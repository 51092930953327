import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Users } from 'react-feather';
import config from '../config.json';
import Table from "../DataTable.js";

const Home = () => {
    const navigate = useNavigate();
    const [userId] = useState(localStorage.getItem('user_id'));
    const [dealerId] = useState(localStorage.getItem('dealer_id'));
    const [dealerName, setDealerName] = useState(localStorage.getItem('dealer_name'));
    const [selectedDealerId] = useState(localStorage.getItem('selected_dealer_id'));
    const [token] = useState(localStorage.getItem('heimdall'));
    const [showProgress, setShowProgress] = useState(false);
    const [dealerData, setDealerData] = useState([{ 'dealer_id': '', 'dealer_name': '' }]);
    const [appData, setAppData] = useState([{
        'rec_app_first_name': '',
        'rec_app_last_name': '',
        'application_number': '',
        'dealer_id': '',
        'rec_dealer_name': '',
        'rec_veh_year': '',
        'rec_veh_make': '',
        'rec_veh_model': '',
        'rec_vin':'',
        'rec_UW_collateral_desc': '',
        'rec_est_amt_financed': '',
        'rec_dealer_proceeds': '',        
        'rec_app_status': '',
        'app_decision_date':''
    }]);
    

    useEffect(() => {
        localStorage.setItem('env', window.location.href.includes('usautocreditportal') ? '/prod' : '/qa')
        document.title = 'Dealer Portal - US Auto Credit';
        
        if (!localStorage.getItem('selected_dealer_id')) {
            localStorage.setItem('selected_dealer_id', dealerId ? dealerId.split(',')[0] : '');
        }
        
        checkLogin();
        fetchData();
    }, [])

    const checkLogin = () => {
        if (localStorage.getItem('heimdall') === null || String(localStorage.getItem('heimdall')).length !== 32) {
            navigate('/login');
        }
    }

    const fetchData = () => {
        fetch('https://9fclqgiiaj.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') == '/prod' ? '/prod' : '') + '/dealergetapplicationlist', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': config.dealer_get_application_list_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'dealer_id': selectedDealerId ? selectedDealerId : '0',
                'user_id': userId ? userId : '0',
                'token': token,
            })
        })
            .then((response) => response.json())
            .then((response) => {
                // console.log(response);
                if (response && response.length > 0) {
                    setAppData(response);
                    setDealerName(response[0].rec_dealer_name);

                    if (response.length > 0) {
                        localStorage.setItem('dealer_name', response[0].rec_dealer_name);
                    }
                }
                
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const getDealerData = (searchText: string) => {
        fetch('https://mu644e02wj.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') == '/prod' ? '/prod' : '') + '/dealerlistget', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': config.dealer_list_get_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'user_id': userId ? userId : '0',
                'token': token,
                'search_text': searchText,
            })
        })
            .then((response) => response.json())
            .then((response) => {
                // console.log(response.length.toString() + ' dealers');
                if (response && response.length > 0) {
                    setDealerData(response);
                }
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const data = appData.map((application, index) => {
        return {
            key: index,
            application_number: application.application_number,
            dealerId : application.dealer_id,
            // rec_dealer_name: dealerId && dealerId.includes(',') ? application.rec_dealer_name : '',
            rec_dealer_name: application.rec_dealer_name ,
            rec_app_name: `${application.rec_app_first_name} ${application.rec_app_last_name}`,
            rec_vin_value: application.rec_vin,                       
            rec_vin: application.rec_vin ? application.rec_vin.slice(-6) : '',           
            rec_UW_collateral_desc: application.rec_UW_collateral_desc,
            rec_vehicle: application.rec_veh_year ? `${application.rec_veh_year} ${application.rec_veh_make} ${application.rec_veh_model}` : '',
            rec_est_amt_financed_value: application.rec_est_amt_financed ? parseInt(application.rec_est_amt_financed.replace(/,/g, '')) : 0,
            rec_est_amt_financed: application.rec_est_amt_financed ? "$" + numberWithCommas(application.rec_est_amt_financed) : "",
            rec_dealer_proceeds_value: application.rec_dealer_proceeds ? parseInt(application.rec_dealer_proceeds.replace(/,/g, '')) : 0,
            rec_dealer_proceeds: application.rec_dealer_proceeds ? "$" + numberWithCommas(application.rec_dealer_proceeds) : "",               
            rec_app_status: application.rec_app_status === "AD" ? "Auto-Declined" :
                application.rec_app_status === "Review Required" ? "Underwriting" :
                    application.rec_app_status,
            app_decision_date: application.app_decision_date,
            // onClick: () => {
            //     console.log("Function was called");
            //     getApplication(application.application_number, application.dealer_id, application.rec_dealer_name)
            //   }
        };
    });

    function changeDealer(new_dealer_id: string, new_dealer_name: string) {
        localStorage.setItem('selected_dealer_id', new_dealer_id);
        localStorage.setItem('dealer_name', new_dealer_name);
        navigate('/');
    }

    function getApplication(applicationNumber: string, applicationDealerId: string, applicationDealerName: string) {
        localStorage.setItem('selected_dealer_id', applicationDealerId);
        localStorage.setItem('dealer_name', applicationDealerName);
        navigate('/rehash/' + applicationNumber);
    }

    const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'dealer_search' && value.length === 3) {
            setShowProgress(true);
            getDealerData(value);
        }
    }

    function numberWithCommas(x: string) {
        if (x != null) {
            return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return '';
        }
    }

    return (
        <>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-4">
                <div className="container-xl px-4">
                    <div className="page-header-content pt-3">
                        <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-4">
                            
                        </div>
                    </div>
                </div>
            </header>

            <div className="container-xl px-4 mt-n10">
                {/* Top Cards */}
                <div className="row" style={{ display: "none", visibility: "hidden" }}>
                    <div className="col-xl-4 mb-4">
                        <a className="card lift h-100" href="/">
                            <div className="card-body d-flex justify-content-center flex-column">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="me-3">
                                        <i className="feather-xl text-primary mb-3" data-feather="package"></i>
                                        <h5>Application Rehash</h5>
                                        <div className="text-muted small">Change vehicle and alter deal structure</div>
                                    </div>
                                    <img src="/assets/img/illustrations/browser-stats.svg" alt="..." style={{ width: "8rem" }} />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xl-4 mb-4">
                        <a className="card lift h-100" href="/">
                            <div className="card-body d-flex justify-content-center flex-column">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="me-3">
                                        <i className="feather-xl text-green mb-3" data-feather="layout"></i>
                                        <h5>Funding</h5>
                                        <div className="text-muted small">Get your applications funded.</div>
                                    </div>
                                    <img src="/assets/img/illustrations/windows.svg" alt="..." style={{ width: "8rem" }} />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xl-4 mb-4">
                        <a className="card lift h-100" href="/">
                            <div className="card-body d-flex justify-content-center flex-column">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="me-3">
                                        <i className="feather-xl text-secondary mb-3" data-feather="book"></i>
                                        <h5>Talk to an Underwriter</h5>
                                        <div className="text-muted small">Speak to us about your applications.</div>
                                    </div>
                                    <img src="/assets/img/illustrations/processing.svg" alt="..." style={{ width: "8rem" }} />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                {/* Original Application Table */}
                <div className="card mb-4" style={{ display: "none", visibility: "hidden" }}>
                    <div className="card-header">{dealerName ? "Recent Applications for " + dealerName : "Select a dealer at the top right of the page."}</div>
                    <div className="card-body table-responsive-sm">
                        <table className="table table-hover table-responsive table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Number</th>
                                    {dealerId && dealerId.includes(',') ? <th scope="col">Dealer</th> : ''}
                                    <th scope="col">Name</th>
                                    <th scope="col">Vehicle</th>
                                    <th scope="col">Amount Financed</th>
                                    <th scope="col">Net Check</th>
                                    <th scope="col">Status</th>
                                    {/* <th scope="col">Rehash</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {appData.map((application, index) => (
                                    <tr key={index} className="align-middle mousepointer" onClick={() => getApplication(application.application_number, application.dealer_id, application.rec_dealer_name)}>
                                        <td>{application.application_number}</td>
                                        {dealerId && dealerId.includes(',') ? <td>{application.rec_dealer_name}</td> : ''}
                                        <td>{application.rec_app_first_name} {application.rec_app_last_name}</td>
                                        <td>{application.rec_UW_collateral_desc}</td>
                                        <td>{application.rec_est_amt_financed ? "$" + numberWithCommas(application.rec_est_amt_financed) : ""}</td>
                                        <td>{application.rec_dealer_proceeds ? "$" + numberWithCommas(application.rec_dealer_proceeds) : ""}</td>
                                        <td>
                                            {
                                                application.rec_app_status === "AD" ? "Auto-Declined" :
                                                    application.rec_app_status === "Review Required" ? "Underwriting" :
                                                        application.rec_app_status
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Datatable */}
                <div className="App" >
                    {/* <div className="card mb-4">
                        <div className="card-header"> Recent Applications for Test Dealer</div>
                        <div className="card-body table-responsive-sm">
                            <Table data={data} />
                        </div>
                    </div> */}
                    <div className="container-xl col-12 px-4 mt-4">
                        <div className="card mb-4">
                            <div className="card-header">
                                <span className="nav_header">
                                    {String(userId).includes('@usautocreditcorp.com') ?
                                        <button className="btn btn-primary btn-small" type="button" data-bs-toggle="modal" data-bs-target="#dealers_modal">Select a Dealer</button>
                                        : <>Recent Applications</>}
                                </span>
                            </div>
                            <div className="card-body card_body_table">
                                <Table data={data} />
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body">
                                If you would like additional help, please contact us. Phone: (877) 280-9267 or 
                                Email: <a href="mailto:underwriting@usautocreditcorp.com" className="text-primary">underwriting@usautocreditcorp.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Dealers Modal */}
            <div className="modal fade" id="dealers_modal" tabIndex={-1} role="dialog" aria-labelledby="dealers_modal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-md-4">
                                <label className="small mb-1" htmlFor="dealer_search"><b>Search by Dealer Name</b></label>
                                <input id="dealer_search" name="dealer_search" type="text" className="form-control app_input input_height" maxLength={3} onChange={handleTextboxChange} autoFocus />
                                {showProgress ? <>Searching...</> : ''}<br />
                            </div>
                            <div className="card mb-4">
                                <div className="card-body table-responsive-sm">
                                    <table className="table table-hover table-responsive table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Dealer ID</th>
                                                <th scope="col">Dealer Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dealerData.map((dealer, index) => (
                                                <tr key={index} className="align-middle mousepointer" onClick={() => changeDealer(dealer.dealer_id, dealer.dealer_name)} data-bs-dismiss="modal">
                                                    <td>{dealer.dealer_id}</td>
                                                    <td>{dealer.dealer_name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};

export default Home;