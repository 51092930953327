import useWebSocket from 'react-use-websocket';
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircle, Feather, XOctagon } from 'react-feather';
import config from '../config.json';

const WS_URL = 'wss://lhl46avx95.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') === '/prod' ? '/prod' : '/qa');

const Rehash = () => {
    const navigate = useNavigate();
    const { applicationNumber } = useParams();
    const [dealerId] = useState(localStorage.getItem('selected_dealer_id') ? localStorage.getItem('selected_dealer_id') : localStorage.getItem('dealer_id'));
    const [userId] = useState(localStorage.getItem('user_id'));
    const [token] = useState(localStorage.getItem('heimdall'));
    const [firstName] = useState(localStorage.getItem('first_name'));
    const [lastName] = useState(localStorage.getItem('last_name'));
    const [acceptedApplication, setAcceptedApplication] = useState(false);
    const [fundingApplication, setFundingApplication] = useState(false);
    const [modifiedApplication, setModifiedApplication] = useState(false);
    const [modifiedVehicle, setModifiedVehicle] = useState(false);
    const [submittedApplication, setSubmittedApplication] = useState(false);
    const [showProgress, setShowProgress] = useState(true);
    const [calculatedMaxAdvance, setCalculatedMaxAdvance] = useState('');
    const [calculatedAmountFinanced, setCalculatedAmountFinanced] = useState('');
    const [dealStips, setDealStips] = useState([]);
    const [dealerNotes, setDealerNotes] = useState([]);
    const [dealerState, setDealerState] = useState('');
    const [currentVin, setCurrentVin] = useState('');
    const [currentMileage, setCurrentMileage] = useState('');
    const [currentVehicleDescription, setcurrentVehicleDescription] = useState('');
    const [newVehicleYear, setNewVehicleYear] = useState('');
    const [newVehicleMake, setNewVehicleMake] = useState('');
    const [newVehicleModel, setNewVehicleModel] = useState('');
    const commentsContent = useRef<HTMLDivElement>(null);
    const [chatbotResponse, setChatbotResponse] = useState('');
    const [chatMessages, setChatMessages] = useState([{ 'date_time': '', 'user_id': '', 'first_name': '', 'last_name': '', 'message': '' }]);
    const [newChatMessage, setNewChatMessage] = useState('');
    const [postedNewChatMessage, setPostedNewChatMessage] = useState(false);
    const [serverMessage, setServerMessage] = useState('');
    const [fundingStatusArray] = useState(['Contract Received', 'Verifications Incomplete', 'Ready to Book', 'Booked']);
    const [appData, setAppData] = useState({
        'rec_app_first_name': '',
        'rec_app_last_name': '',
        'rec_app_streetno': '',
        'rec_app_streetname': '',
        'rec_app_aptno': '',
        'rec_app_city': '',
        'rec_app_state': '',
        'rec_app_zip': '',
        'rec_app_addr_years': '',
        'rec_app_addr_months': '',
        'rec_app_emp_name': '',
        'rec_app_emp_total_months': '',
        'rec_app_emp_years': '',
        'rec_app_emp_months': '',
        'rec_app_income': '',
        'rec_app_other_income_source': '',
        'rec_app_other_income': '',
        'rec_app_ttl_income': '',
        'rec_app_pti': '',
        'rec_coapp_first_name': '',
        'rec_coapp_last_name': '',
        'rec_coapp_streetno': '',
        'rec_coapp_streetname': '',
        'rec_coapp_aptno': '',
        'rec_coapp_city': '',
        'rec_coapp_state': '',
        'rec_coapp_zip': '',
        'rec_coapp_addr_years': '',
        'rec_coapp_addr_months': '',
        'rec_coapp_emp_name': '',
        'rec_coapp_emp_total_months': '',
        'rec_coapp_emp_years': '',
        'rec_coapp_emp_months': '',
        'rec_coapp_income': '',
        'rec_coapp_other_income_source': '',
        'rec_coapp_other_income': '',
        'rec_application_number': '',
        'rec_app_status': '',
        'req_sales_price': '',
        'rec_sales_price': '',
        'estimated_sales_tax': '',
        'estimated_ttl': '',
        'req_cash_down': '',
        'rec_cash_down': '',
        'req_net_trade': '',
        'rec_net_trade': '',
        'req_unpaid_balance': '',
        'rec_unpaid_balance': '',
        'req_backend_allowance': '',
        'rec_backend_allowance': '',
        'req_gap': '',
        'rec_gap': '',
        'rec_gap_dca_max': '',
        'rec_credit_life_ins': '',  // rec_fldocstamptaxUW
        'req_amt_financed': '',
        'rec_est_amt_financed': '',
        'rec_calc_discount': '',
        'req_rate': '',
        'req_system_buyrate': '',
        'rec_buyrate': '',
        'rec_orig_fee': '',
        'rec_dealer_proceeds': '',
        'req_term_months': '',
        'rec_term_months': '',
        'req_payment': '',
        'rec_payment': '',
        'rec_payment_freq': '',
        'rec_line3ltv': '',  // LTV without Gap
        'rec_ltv_ratio': '',  // LTV with Gap
        'rec_dealer_id': '',
        'rec_dealer_name': '',
        'rec_dealer_citystatezip': '',
        'rec_dealer_email': '',
        'rec_dealer_phone': '',
        'rec_UW_collateral_desc': '',
        'rec_veh_year': '',
        'rec_veh_make': '',
        'rec_veh_model': '',
        'rec_veh_trim': '',
        'rec_veh_mileage': '',
        'rec_vin': '',
        'rec_veh_bookout_value': '',
        'vehicle_age': '',
        'rec_tier': '',
        'rec_buyer_tier': '',
        'min_income': '',
        'max_pti': '',
        'max_ltv': '',
        'max_amt_fin_less_gap': '',
        'max_term': '',
        'max_payment': '',
        'max_miles': '',
        'max_down_payment': '',
        'min_toj_months': '',
        'rec_AS_indicator': '',
        'accept_deal': '',
        'dealer_portal_app': '',
        'app_received_date': '',
        'app_decision_date': '',
        'cr_received_date': '',
        'vi_entry_date': '',
        'vi_end_date': '',
        'rec_dealer_branch': '',
        'rec_analyst_fullname': '',
        'deal_stips': '',
        'dealer_notes': ''
    });

    useEffect(() => {
        localStorage.setItem('env', window.location.href.includes('usautocreditportal') ? '/prod' : 'qa')
        document.title = 'Rehash Application';
        checkLogin();
        fetchData();
        getComments();
        window.scrollTo(0, 0);
    }, [])

    const {
        getWebSocket } = useWebSocket(WS_URL + '?userId=' + userId + '&applicationNumber=' + applicationNumber, {
            onOpen: () => console.log('Connected!'),
            onMessage: (event) => {
                setServerMessage(event.data);
                setPostedNewChatMessage(false);
                setSubmittedApplication(false);
                fetchData();
                getComments();
            },
            shouldReconnect: (closeEvent) => true,
        });

    const scrollToBottom = () => {
        if (commentsContent) {
            if (commentsContent.current) {
                commentsContent.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const checkLogin = () => {
        if (localStorage.getItem('heimdall') === null || String(localStorage.getItem('heimdall')).length !== 32) {
            navigate('/login');
        }
    }

    const decodeVin = (vin: string) => {
        fetch('https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/' + vin + '?format=json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response.Results[10].Value);
                console.log(response.Results[7].Value);
                console.log(response.Results[9].Value);
                setNewVehicleYear(response.Results[10].Value);
                setNewVehicleMake(capitalizeFirstLetter(response.Results[7].Value));
                setNewVehicleModel(response.Results[9].Value);
                setAppData(values => ({
                    ...values,
                    ['rec_UW_collateral_desc']:
                        response.Results[10].Value + ' ' +
                        capitalizeFirstLetter(response.Results[7].Value) + ' ' +
                        response.Results[9].Value
                }));
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const fetchData = () => {
        fetch('https://zde542glzf.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') === '/prod' ? '/prod' : '') + '/dealergetapplication', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': config.dealer_get_application_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'application_number': applicationNumber,
                'dealer_id': dealerId ? dealerId : '0',
                'user_id': userId ? userId : '0',
                'token': token
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setAppData(response);
                // console.log(response);

                // if (response.accept_deal && response.accept_deal.toLowerCase() === 'yes' && response.rec_app_status !== 'Review Required') {
                if (response.accept_deal && response.accept_deal.toLowerCase() === 'yes') {
                    setAcceptedApplication(true);
                }

                if (['RA for Payment Call', 'Review Required', 'Return to UW'].includes(response.rec_app_status)) {
                    setSubmittedApplication(true);
                }

                if (fundingStatusArray.includes(response.rec_app_status)) {
                    setFundingApplication(true);
                }

                if (response.deal_stips) {
                    var stipsObject = JSON.parse(response.deal_stips);
                    setDealStips(stipsObject.DealStips);
                    // console.log(JSON.stringify(stipsObject.Stipulations[0].Name));
                }

                if (response.dealer_notes) {
                    var dealerNotesObject = JSON.parse(response.dealer_notes);
                    setDealerNotes(dealerNotesObject.DealerNotes);
                    // console.log(JSON.stringify(dealerNotesObject.DealerNotes[0]));
                }

                if (response.comments) {
                    // var commentsObject = JSON.parse(response.comments);
                    // setComments(commentsObject.Comments);
                    // console.log(JSON.stringify(commentsObject.Comments[0]));
                }

                if (response.rec_vin) {
                    setCurrentVin(response.rec_vin);
                }

                if (response.rec_veh_mileage) {
                    setCurrentMileage(response.rec_veh_mileage);
                }

                if (!response.rec_dealer_citystatezip || (response.rec_dealer_citystatezip && (response.rec_dealer_citystatezip.includes(' FL') || response.rec_dealer_citystatezip.includes('FL ')))) {
                    setDealerState('FL');
                } else {
                    setDealerState('');
                }

                if (response.rec_UW_collateral_desc) {
                    setcurrentVehicleDescription(response.rec_UW_collateral_desc);
                }

                setCalculatedMaxAdvance((parseFloat(response.req_sales_price) - parseFloat(response.req_cash_down) - parseFloat(response.req_net_trade)).toString());
                setCalculatedAmountFinanced((parseFloat(response.req_sales_price) - parseFloat(response.req_cash_down) - parseFloat(response.req_net_trade) + parseFloat(response.req_gap ? response.req_gap : '0') + parseFloat(response.rec_credit_life_ins)).toString());
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const getComments = () => {
        fetch('https://79oiyytnid.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') === '/prod' ? '/prod' : '') + '/dealerchatmessagesget', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': config.dealer_chat_messages_get_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'application_number': applicationNumber,
                'dealer_id': dealerId ? dealerId : '0',
                'user_id': userId ? userId : '0',
                'token': token,
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setChatMessages(response);
                // console.log(response);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
        
        if (commentsContent.current) {
            commentsContent.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    function fancyTooltip(labelId: string) {
        var popup = document.getElementById(labelId);

        if (popup) {
            popup.classList.toggle("show");
        }
        
        // setTimeout(function () {
        //     document.getElementById("myPopup").style.display = "none";
        // }, 3000);
    }

    function updateApplication(dealAccepted: boolean) {
        setAcceptedApplication(dealAccepted);
        setSubmittedApplication(dealAccepted ? false : true);
        setModifiedApplication(false);
        setModifiedVehicle(false);
        setServerMessage('');
        
        fetch('https://9kd9sezz5a.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') === '/prod' ? '/prod' : '') + '/dealerpublishupdatedapplication', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 
                    config.dealer_publish_updated_application_token + '/' + 
                    (dealerId ? dealerId : '0') + '/' + 
                    (userId ? userId : '0') + '/' + 
                    (token ? token : '0'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'rec_application_number': appData.rec_application_number ? appData.rec_application_number : '',
                'rec_app_first_name': appData.rec_app_first_name ? appData.rec_app_first_name : '',
                'rec_app_last_name': appData.rec_app_last_name ? appData.rec_app_last_name : '',
                'rec_app_streetno': appData.rec_app_streetno ? appData.rec_app_streetno : '',
                'rec_app_streetname': appData.rec_app_streetname ? appData.rec_app_streetname : '',
                'rec_app_aptno': appData.rec_app_aptno ? appData.rec_app_aptno : '',
                'rec_app_city': appData.rec_app_city ? appData.rec_app_city : '',
                'rec_app_state': appData.rec_app_state ? appData.rec_app_state : '',
                'rec_app_zip': appData.rec_app_zip ? appData.rec_app_zip : '',
                'rec_app_addr_years': appData.rec_app_addr_years ? appData.rec_app_addr_years : '',
                'rec_app_addr_months': appData.rec_app_addr_months ? appData.rec_app_addr_months : '',
                'rec_app_emp_name': appData.rec_app_emp_name ? appData.rec_app_emp_name : '',
                'rec_app_emp_years': appData.rec_app_emp_years ? appData.rec_app_emp_years : '',
                'rec_app_emp_months': appData.rec_app_emp_months ? appData.rec_app_emp_months : '',
                'rec_app_income': appData.rec_app_income ? appData.rec_app_income : '',
                'rec_app_other_income_source': appData.rec_app_other_income_source ? appData.rec_app_other_income_source : '',
                'rec_app_other_income': appData.rec_app_other_income ? appData.rec_app_other_income : '',
                'rec_coapp_first_name': appData.rec_coapp_first_name ? appData.rec_coapp_first_name : '',
                'rec_coapp_last_name': appData.rec_coapp_last_name ? appData.rec_coapp_last_name : '',
                'rec_coapp_streetno': appData.rec_coapp_streetno ? appData.rec_coapp_streetno : '',
                'rec_coapp_streetname': appData.rec_coapp_streetname ? appData.rec_coapp_streetname : '',
                'rec_coapp_aptno': appData.rec_coapp_aptno ? appData.rec_coapp_aptno : '',
                'rec_coapp_city': appData.rec_coapp_city ? appData.rec_coapp_city : '',
                'rec_coapp_state': appData.rec_coapp_state ? appData.rec_coapp_state : '',
                'rec_coapp_zip': appData.rec_coapp_zip ? appData.rec_coapp_zip : '',
                'rec_coapp_addr_years': appData.rec_coapp_addr_years ? appData.rec_coapp_addr_years : '',
                'rec_coapp_addr_months': appData.rec_coapp_addr_months ? appData.rec_coapp_addr_months : '',
                'rec_coapp_emp_name': appData.rec_coapp_emp_name ? appData.rec_coapp_emp_name : '',
                'rec_coapp_emp_years': appData.rec_coapp_emp_years ? appData.rec_coapp_emp_years : '',
                'rec_coapp_emp_months': appData.rec_coapp_emp_months ? appData.rec_coapp_emp_months : '',
                'rec_coapp_income': appData.rec_coapp_income ? appData.rec_coapp_income : '',
                'rec_coapp_other_income_source': appData.rec_coapp_other_income_source ? appData.rec_coapp_other_income_source : '',
                'rec_coapp_other_income': appData.rec_coapp_other_income ? appData.rec_coapp_other_income : '',
                'req_sales_price': appData.req_sales_price ? appData.req_sales_price : '',
                'rec_sales_price': appData.req_sales_price ? appData.req_sales_price : '',  // Note: rec = req
                'req_cash_down': appData.req_cash_down ? appData.req_cash_down : '',
                'rec_cash_down': appData.req_cash_down ? appData.req_cash_down : '',
                'req_net_trade': appData.req_net_trade ? appData.req_net_trade : '',
                'rec_net_trade': appData.req_net_trade ? appData.req_net_trade : '',
                'req_unpaid_balance': appData.req_unpaid_balance ? appData.req_unpaid_balance : '',
                'rec_unpaid_balance': calculatedMaxAdvance ? calculatedMaxAdvance : '',
                'req_gap': appData.req_gap ? appData.req_gap : '',
                'req_rate': appData.req_rate ? appData.req_rate : '',
                'req_term_months': appData.req_term_months ? appData.req_term_months : '',
                'rec_term_months': appData.req_term_months ? appData.req_term_months : '',
                'req_payment': appData.req_payment ? appData.req_payment : '',
                'rec_payment': appData.req_payment ? appData.req_payment : '',
                'rec_payment_freq': appData.rec_payment_freq ? appData.rec_payment_freq : '',
                'rec_vin': appData.rec_vin ? appData.rec_vin : '',
                'rec_veh_mileage': appData.rec_veh_mileage ? appData.rec_veh_mileage : '',
                'rec_AS_indicator': 'DP',
                'accept_deal': dealAccepted ? 'Yes' : 'No',
                'dealer_portal_app': 'Yes',
                'rec_app_status': appData.rec_app_status ? appData.rec_app_status : '',
                'env': localStorage.getItem('env') ? localStorage.getItem('env') : ''
            })
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    function upsertChatMessage() {
        var currentDateTime = new Date();
        setChatMessages(previousChatMessages => ([...previousChatMessages, {
            'date_time': currentDateTime.toString(),
            'user_id': userId ? userId : '',
            'first_name': firstName ? firstName : '',
            'last_name': lastName ? lastName : '',
            'message': newChatMessage
        }]));
        setNewChatMessage('');
        setChatbotResponse(' is writing a response...');
        setPostedNewChatMessage(true);
        setServerMessage('');
        
        fetch('https://lybaq5bkd0.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') === '/prod' ? '/prod' : '') + '/dealerchatmessageupsert', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization':
                    config.dealer_chat_message_upsert_token + '/' +
                    (dealerId ? dealerId : '0') + '/' +
                    (userId ? userId : '0') + '/' +
                    (token ? token : '0'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'application_number': applicationNumber,
                'message': newChatMessage,
                'env': localStorage.getItem('env') ? localStorage.getItem('env') : ''
            })
        })
            .then((response) => response.json())
            .then((response) => {
                // console.log(response);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
        
        fetch('https://wg5b9u6hue.execute-api.us-east-2.amazonaws.com' + (localStorage.getItem('env') === '/prod' ? '/prod' : '') + '/aiglynn', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': config.ai_glynn_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'prompt': newChatMessage,
                'user': firstName
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setChatbotResponse(': ' + response.content);
                // setChatMessages(previousChatMessages => ([...previousChatMessages, {
                //     'date_time': currentDateTime.toString(),
                //     'user_id': 'GlynnTheChatbot',
                //     'first_name': 'Glynn',
                //     'last_name': 'the Chatbot',
                //     'message': response.content
                // }]));
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;

        setAppData(values => ({ ...values, [name]: value }));
        setModifiedApplication(true);

        if (!appData.req_gap) {
            appData.req_gap = '0';
        }

        if (name === 'req_sales_price') {
            setCalculatedMaxAdvance((parseFloat(value) - parseFloat(appData.req_cash_down) - parseFloat(appData.req_net_trade)).toString());
            setCalculatedAmountFinanced((parseFloat(value) - parseFloat(appData.req_cash_down) - parseFloat(appData.req_net_trade) + parseFloat(appData.req_gap) + parseFloat(appData.rec_credit_life_ins)).toString());
        } else if (name === 'req_cash_down') {
            setCalculatedMaxAdvance((parseFloat(appData.req_sales_price) - parseFloat(value) - parseFloat(appData.req_net_trade)).toString());
            setCalculatedAmountFinanced((parseFloat(appData.req_sales_price) - parseFloat(value) - parseFloat(appData.req_net_trade) + parseFloat(appData.req_gap) + parseFloat(appData.rec_credit_life_ins)).toString());
        } else if (name === 'req_net_trade') {
            setCalculatedMaxAdvance((parseFloat(appData.req_sales_price) - parseFloat(appData.req_cash_down) - parseFloat(value)).toString());
            setCalculatedAmountFinanced((parseFloat(appData.req_sales_price) - parseFloat(appData.req_cash_down) - parseFloat(value) + parseFloat(appData.req_gap) + parseFloat(appData.rec_credit_life_ins)).toString());
        } else if (name === 'req_gap') {
            setCalculatedAmountFinanced((parseFloat(appData.req_sales_price) - parseFloat(appData.req_cash_down) - parseFloat(appData.req_net_trade) + parseFloat(value) + parseFloat(appData.rec_credit_life_ins)).toString());
        }

        if (name === 'rec_vin' && value.length === 17) {
            decodeVin(value);
            if (value != currentVin) {
                setModifiedVehicle(true);
            }
            
        }
    }

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'newChatMessage') {
            setNewChatMessage(value);
        }
    }

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setAppData(values => ({ ...values, [name]: value }));
        setModifiedApplication(true);
    };

    function capitalizeFirstLetter(text: string) {
        if (text != null && text.length > 0) {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        } else {
            return '';
        }
    }

    function numberWithCommas(x: string) {
        if (x != null) {
            return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return '';
        }
    }

    return (
        <>
            <div className="container-xl  col-12 px-4 mt-4">
                <div className="row">
                    <div className="col-xl-8">
                        {/* Sub-Nav card */}
                        <div className="card mb-2">
                            <div className="card-body">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link main_nav active" id="pills-structure-tab" data-bs-toggle="pill" data-bs-target="#pills-structure" type="button" role="tab" aria-controls="pills-structure" aria-selected="false">Structure</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link main_nav" type="button" data-bs-toggle="modal" data-bs-target="#comments_modal">Submit Comments</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link main_nav" type="button" data-bs-toggle="modal" data-bs-target="#contact_us_modal">Contact Us</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-structure" role="tabpanel" aria-labelledby="pills-structure-tab">
                                <div className="row">
                                    <div className="col-xl-12">
                                        {/* Deal Structure */}
                                        <div className="card mb-1">
                                            <div className="card-header">
                                                {appData.rec_app_first_name ? 'Deal Structure for ' + appData.rec_app_first_name + ' ' + appData.rec_app_last_name : 'Invalid Application'}
                                                {showProgress ? <img src="/images/rainbow-spinner.gif" alt="spinner" id="progressindicator" height="10px" style={{ marginLeft: "5px" }} /> : ''}
                                                {modifiedVehicle ? <span style={{ color: 'darkorange', fontSize: '12px', marginLeft: '15px' }}> Vehicle changed. Sales Price should be adjusted.</span> : ''}
                                                <div className="App_Btn">
                                                    <button className="btn btn-sm btn-success" style={{ paddingLeft: "45px", paddingRight: "45px" }} data-bs-toggle="modal" data-bs-target="#accept_modal"
                                                        disabled={acceptedApplication || fundingApplication || submittedApplication || !appData.rec_application_number || (appData.rec_app_status !== 'Approved' && appData.rec_app_status !== 'Conditioned')}
                                                        title="Accept this approval and start the funding process." onClick={() => updateApplication(true)}>
                                                        Accept
                                                    </button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    {/* <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#structures">Previous Structures</button> */}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    {/* Requested Structure */}
                                                    <div className="col-sm-6">
                                                        <form>
                                                            <input type="hidden" value="autocompleteworkaround" />
                                                            <div className="form-group row">
                                                                <h6 className="col-12"><i className="material-icons text-info  pr-2 mr-2">Requested</i> Structure</h6>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="req_sales_price" className="col-5 col-form-label">
                                                                    Sales Price &nbsp;
                                                                    <div className="popup" onClick={() => fancyTooltip('reqSalesPriceLabel')}>
                                                                        <div className="question_mark">
                                                                            <i style={{color: "#fff"}} className="fa-solid fa-question"></i>
                                                                        </div>
                                                                        <span className="popuptext" id="reqSalesPriceLabel">
                                                                            Sales Price includes TAX, TTL & Fees
                                                                        </span>
                                                                    </div>
                                                                </label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_sales_price" name="req_sales_price" type="number" min="0" className="form-control itemization input_height" autoComplete="off" value={appData.req_sales_price} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="req_cash_down" className="col-5 col-form-label">Cash Down Payment</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_cash_down" name="req_cash_down" type="number" min="0" className="form-control itemization input_height" autoComplete="off" value={appData.req_cash_down} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="req_net_trade" className="col-5 col-form-label">Trade-In Credit</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_net_trade" name="req_net_trade" type="number" min="0" className="form-control itemization input_height" autoComplete="off" value={appData.req_net_trade} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="req_unpaid_balance" className="col-5 col-form-label">Max Advance</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_unpaid_balance" name="req_unpaid_balance" type="text" className="form-control itemization input_height" value={calculatedMaxAdvance ? calculatedMaxAdvance : ''} readOnly />
                                                                </div>
                                                            </div>
                                                            {appData.rec_buyer_tier === 'PREM' ?
                                                                <div className="form-group row">
                                                                    <label htmlFor="req_backend_allowance" className="col-5 col-form-label">Max Back End</label>
                                                                    <div className="col-5 mt-1 mb-1">
                                                                        <input id="req_backend_allowance" name="req_backend_allowance" type="text" className="form-control itemization input_height" autoComplete="off" value={appData.req_backend_allowance !== '0' ? appData.req_backend_allowance : '0.00'} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                    </div>
                                                                </div> : ''}
                                                            <div className="form-group row">
                                                                <label htmlFor="req_gap" className="col-5 col-form-label">Gap</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    {/* <input type="text" className="form-control itemization input_height" defaultValue={numberWithCommas(appData.req_gap)} readOnly /> */}
                                                                    <input id="req_gap" name="req_gap" type="number" min="0" className="form-control itemization input_height" autoComplete="off" value={appData.req_gap ? appData.req_gap : ''} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                </div>
                                                            </div>
                                                            {dealerState === 'FL' ?
                                                                <div className="form-group row">
                                                                    <label htmlFor="staticEmail" className="col-5 col-form-label">FL Doc Stamp Tax</label>
                                                                    <div className="col-5 mt-1 mb-1">
                                                                        <input type="number" min="0" className="form-control itemization input_height" defaultValue={numberWithCommas(appData.rec_credit_life_ins)} readOnly />
                                                                    </div>
                                                                </div> : ''}
                                                            <div className="form-group row">
                                                                <label htmlFor="req_amt_financed" className="col-5 col-form-label">Amount Financed</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_amt_financed" name="req_amt_financed" type="text" className="form-control itemization input_height" value={calculatedAmountFinanced ? calculatedAmountFinanced : ''} readOnly />
                                                                </div>
                                                            </div>

                                                            <hr style={{ marginTop: "16px" }} />

                                                            <div className="form-group row">
                                                                <label htmlFor="req_term_months" className="col-5 col-form-label">Monthly Term</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_term_months" name="req_term_months" type="number" min="0" className="form-control itemization input_height" autoComplete="off" value={appData.req_term_months} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="req_rate" className="col-5 col-form-label">Rate</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_rate" name="req_rate" type="number" min="0" className="form-control itemization input_height" autoComplete="off" value={appData.req_rate ? appData.req_rate : ''} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="rec_payment_freq" className="col-5 col-form-label">Payment Frequency</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <select id="rec_payment_freq" name="rec_payment_freq" className=" form-select dropdown" aria-label="Default select example" value={appData.rec_payment_freq} onChange={handleSelectChange} disabled={fundingApplication || submittedApplication}>
                                                                        <option value="Bi Weekly">Bi Weekly</option>
                                                                        <option value="Monthly">Monthly</option>
                                                                        <option value="Semi Monthly">Semi Monthly</option>
                                                                        <option value="Weekly">Weekly</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="req_payment}" className="col-5 col-form-label">Payment</label>
                                                                <div className="col-5 mt-1 mb-1">
                                                                    <input id="req_payment" name="req_payment" type="number" min="0" className="form-control itemization input_height" autoComplete="off" value={appData.req_payment ? appData.req_payment : ''} onChange={handleChange} readOnly={fundingApplication || submittedApplication} />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="form-group row">
                                                                <div className="col-12 text-center">
                                                                    <button type="button" className="btn btn-success mx-3" onClick={() => updateApplication(false)} data-bs-toggle="modal" data-bs-target="#post_submit_modal"
                                                                        disabled={fundingApplication || submittedApplication || !modifiedApplication || !appData.rec_application_number}>
                                                                        Rehash
                                                                    </button>
                                                                    &nbsp;&nbsp;
                                                                    {
                                                                        fundingApplication ? <><br /><br />This application is now being funded.</> :
                                                                            submittedApplication ? <><br /><br />This application has been submitted.</> :
                                                                                !modifiedApplication ? <><br /><br />You have not yet modified this application.</> : ''
                                                                    }
                                                                    {/* <button type="button" className="btn btn-primary px-4">Clear</button> */}
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    {/* Decisioned Structure */}
                                                    <div className="col-sm-6">
                                                        <form>
                                                            <input type="hidden" value="autocompleteworkaround" />
                                                            <div className="form-group row">
                                                                <h6 className="col-12"><i className="material-icons text-success pr-2 mr-2">Decisioned</i> Structure</h6>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">
                                                                    Sales Price &nbsp;
                                                                    <div className="popup" onClick={() => fancyTooltip('recSalesPriceLabel')}>
                                                                        <div className="question_mark">
                                                                            <i style={{ color: "#fff" }} className="fa-solid fa-question"></i>
                                                                        </div>
                                                                        <span className="popuptext" id="recSalesPriceLabel">
                                                                            Sales Price includes TAX, TTL & Fees
                                                                        </span>
                                                                    </div>
                                                                </label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_sales_price)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Cash Down Payment</label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_cash_down)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Trade-In Credit</label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_net_trade)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Max Advance</label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_unpaid_balance)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {appData.rec_buyer_tier === 'PREM' ? 
                                                                <div className="form-group row ">
                                                                    <label htmlFor="staticEmail" className="col-5 col-form-label">Max Back End</label>
                                                                    <div className="col-5 dec_structure_amount">
                                                                        <p className="input_height">
                                                                            {
                                                                                submittedApplication ? 'Pending' :
                                                                                    (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                        '$' + numberWithCommas(appData.rec_backend_allowance)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div> : ''}
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Gap</label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_gap)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {dealerState === 'FL' ?
                                                                <div className="form-group row ">
                                                                    <label htmlFor="staticEmail" className="col-5 col-form-label">FL Doc Stamp Tax</label>
                                                                    <div className="col-5 dec_structure_amount">
                                                                        <p className="input_height">
                                                                            {
                                                                                submittedApplication ? 'Pending' :
                                                                                    (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                        '$' + numberWithCommas(appData.rec_credit_life_ins)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div> : ''}
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Amount Financed</label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_est_amt_financed)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Monthly Term</label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    appData.rec_term_months + ' months'
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Rate</label>
                                                                <div className="col-5 dec_structure_amount">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    appData.rec_buyrate + '%'
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Payment Frequency</label>
                                                                <p className="col-5 col-form-label">
                                                                    {
                                                                        submittedApplication ? 'Pending' :
                                                                            (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                appData.rec_payment_freq
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="form-group row ">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Payment</label>
                                                                <div className="col-5 dec_structure_amount input_height">
                                                                    <p>
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_payment)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="form-group row">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Discount</label>
                                                                <div className="col-5 mt-2">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + numberWithCommas(appData.rec_calc_discount)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Origination Fee</label>
                                                                <div className="col-5 mt-2">
                                                                    <p className="input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    '$' + (appData.rec_orig_fee ? parseFloat(appData.rec_orig_fee).toFixed(2) : '0.00')
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label htmlFor="staticEmail" className="col-5 col-form-label">Net Check</label>
                                                                <div className="col-5 mt-2">
                                                                    <p className="dealer_proceeds input_height">
                                                                        {
                                                                            submittedApplication ? 'Pending' :
                                                                                (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '-' :
                                                                                    <b>${numberWithCommas(appData.rec_dealer_proceeds)}</b>
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4">
                        {/* Account details card */}
                        <div className="card mb-2">
                            <div className="card h-100 border-start-lg border-start-success">
                                <div className="card-body">
                                    <div className="h3 d-flex align-items-center">Status: &nbsp;
                                        {
                                            submittedApplication ? <Feather color="blue" /> :
                                                (acceptedApplication || appData.rec_app_status === 'Approved' || appData.rec_app_status === 'Conditioned') ? <CheckCircle color="limegreen" /> :
                                                    (appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? <XOctagon color="red" /> :
                                                        <Feather color="green" />
                                        }
                                        &nbsp;
                                        {
                                            fundingStatusArray.includes(appData.rec_app_status) ? appData.rec_app_status :
                                                acceptedApplication ? 'Accepted' :
                                                    (appData.rec_app_status === 'Approved' || appData.rec_app_status === 'Conditioned') && !submittedApplication ? appData.rec_app_status :
                                                        String(appData.rec_AS_indicator).includes('Rehash') ? 'Under Review' :
                                                            submittedApplication ? 'Underwriting in Progress' :
                                                                appData.rec_app_status === 'AD' ? 'Auto-Declined' :
                                                                    appData.rec_app_status
                                        }
                                    </div>
                                    <div className="small text-dark">
                                        Application Number: &nbsp;
                                        {appData.rec_application_number}
                                    </div>
                                    <div className="small text-dark">Program Qualification: &nbsp; {appData.rec_buyer_tier}</div>
                                    <div className="small text-success"><b>Dealer Proceeds:</b> &nbsp;
                                        <b>
                                            {submittedApplication ? "Pending" : "$" +
                                                ((appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined') ? '0' : numberWithCommas(appData.rec_dealer_proceeds))
                                            }
                                        </b>
                                    </div>
                                    {submittedApplication ? '' :
                                        <a href="/#" className="small text-primary" data-bs-toggle="modal"
                                            data-bs-target=
                                            {appData.rec_app_status === 'AD' || appData.rec_app_status === 'Declined' ? '#decline_callback_modal' :
                                                fundingApplication ? '#stip_sheet_modal' :
                                                    '#approval_callback_modal'}>
                                            <b>{fundingApplication ? 'See Stip Sheet' : 'See Call Back'}</b>
                                        </a>}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12">
                            {/* Customer Information */}
                            <div className="card mb-2">
                                <div className="card-header">
                                    Customer Information
                                    {showProgress ? <img src="/images/rainbow-spinner.gif" alt="spinner" height="20px" id="progressindicator" style={{ marginLeft: "5px" }} /> : <></>}
                                    <button className="btn btn-sm btn-primary str_btn" style={{ float: "right" }} type="button" data-bs-toggle="modal" data-bs-target="#customer_modal" disabled={fundingApplication || submittedApplication || !appData.rec_application_number}>Edit</button>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Applicant Name:</label>
                                            <p className="">{appData.rec_app_first_name} {appData.rec_app_last_name}</p>
                                        </div>
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Co-Applicant Name:</label>
                                            <p className="">{appData.rec_coapp_first_name} {appData.rec_coapp_last_name}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Employer Name:</label>
                                            <p className="">{appData.rec_app_emp_name}</p>
                                        </div>
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Employer Name:</label>
                                            <p className="">{appData.rec_coapp_emp_name}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Monthly Income:</label>
                                            <p className="">{appData.rec_app_income ? '$' + numberWithCommas(appData.rec_app_income) : ''}</p>
                                        </div>
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Monthly Income:</label>
                                            <p className="">{appData.rec_coapp_income ? '$' + numberWithCommas(appData.rec_coapp_income) : ''}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Months on Job:</label>
                                            <p className="">{appData.rec_app_emp_total_months ? appData.rec_app_emp_total_months + ' months' : ''}</p>
                                        </div>
                                        <div className="col-6">
                                            <label className="large mb-1 vehicle_label">Months on Job:</label>
                                            <p className="">{appData.rec_coapp_emp_total_months ? appData.rec_coapp_emp_total_months + ' months' : ''}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Vehicle Information */}
                            <div className="card mb-1">
                                <div className="card-header">
                                    Vehicle Information
                                    {showProgress ? <img src="/images/rainbow-spinner.gif" alt="spinner" height="20px" id="progressindicator" style={{ marginLeft: "5px" }} /> : ''}
                                    <button className="btn btn-sm btn-primary str_btn" style={{ float: "right" }} type="button" data-bs-toggle="modal" data-bs-target="#vehicle_modal" disabled={fundingApplication || submittedApplication || !appData.rec_application_number}>Edit</button>
                                </div>
                                <div className="card-body">
                                    <div className="row gx-3 ">
                                        <div className="col-md-6">
                                            <label className="large  mb-1 vehicle_label">Vehicle</label>
                                            <p>{appData.rec_UW_collateral_desc}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="large mb-1 vehicle_label">Mileage</label>
                                            <p>{numberWithCommas(appData.rec_veh_mileage)} miles</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="large mb-1 vehicle_label">VIN</label>
                                            <p>{appData.rec_vin}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Stipulations */}
                            <div className="card mb-1">
                                <div className="card-header">Stipulations</div>
                                <div className="card-body">
                                    {/* <ul>
                                        {dealStips.map((dealStip, index) => (
                                            <li key={index}>
                                                {String(dealStip).split('|')[0]}
                                            </li>
                                        ))}
                                    </ul> */}
                                    <ul>
                                        {dealStips.map((dealStip, index) => {
                                            if (String(dealStip).includes('|')) {
                                                var stipName = String(dealStip).split('|')[0];
                                                var stipStatus = String(dealStip).split('|')[1];
                                                var stipType = String(dealStip).split('|')[2];
                                                if (['Failed', 'Incomplete'].includes(stipStatus) && ['UW', 'VF'].includes(stipType)) {
                                                    return <li key={index}>{stipName}</li>;
                                                }
                                            } else {
                                                var stipName = String(dealStip).split('|')[0];
                                                return <li key={index}>{stipName}</li>;
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>

                            {/* U.S. Auto Credit Notes to Dealer */}
                            <div className="card mb-1">
                                <div className="card-header">U.S. Auto Credit Notes to Dealer</div>
                                <div className="card-body">
                                    <ul>
                                        {dealerNotes.map((dealerNote, index) => (
                                            <li key={index}>
                                                {dealerNote}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <div style={{ marginLeft: '55px' }}>
                                        {/* Debugging Area */}
                                        {/* {submittedApplication ? 'App submitted' : 'App not yet submitted'}<br /><br /> */}
                                        {/* {serverMessage ? serverMessage.split('|')[2].split('"')[0] : ''}<br /> */}
                                        {/* {serverMessage}<br /><br /><br /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="mt-0 mb-4"></span>
                </div>
            </div>

            {/* Accept Modal */}
            <div className="modal fade" id="accept_modal" tabIndex={-1} role="dialog" aria-labelledby="accept_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img src="/images/offer-accepted.png" alt="check" style={{ marginLeft: '18px', marginRight: '32px' }} width="100px" />
                            <h6 className="modal-title">
                                Congratulations! Please check&nbsp;
                                {appData.rec_dealer_email ? <span className="text-primary">{appData.rec_dealer_email}</span> : "your dealership's email"}
                                &nbsp;for your closing documents. Our team is eager to work your deal and provide you with the best 
                                service possible. To ensure a smooth and efficient process, we kindly request that you send all 
                                required documents and stipulations to&nbsp;
                                <a href="mailto:Autocreditfunding@usautocreditcorp.com" className="text-primary">Autocreditfunding@usautocreditcorp.com</a>&nbsp;
                                as soon as possible.
                            </h6>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="button" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Approval Callback Modal */}
            <div className="modal fade" id="approval_callback_modal" tabIndex={-1} role="dialog" aria-labelledby="approval_callback_modal" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Call Back</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive">
                                <p className="CB_Header">U.S. AUTO CREDIT PURCHASING CENTER, LLC</p>
                                <p className="CB_sub_Header">{appData.rec_app_status}</p>
                                <table className=" table table-striped table-bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} rowSpan={1} className="CI_Modal"><h4>Customer Information</h4></td>
                                            <td>&nbsp;</td>
                                            <td colSpan={2} rowSpan={1} className="FI_Modal"><h4>Financing Information</h4></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Applicant</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_app_first_name} {appData.rec_app_last_name}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Max Advance</h5></td>
                                            <td className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_unpaid_balance)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Co-Applicant, if any</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_coapp_first_name} {appData.rec_coapp_last_name}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>GAP Protection</h5></td>
                                            <td className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_gap)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Application #</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_application_number}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>FL Doc Stamp Tax, if any</h5></td>
                                            <td className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_credit_life_ins)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Application Date</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.app_received_date}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Total Amount Financed</h5></td>
                                            <td className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_est_amt_financed)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Decision Date</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.app_decision_date}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Discount/Lender Fee</h5></td>
                                            <td className="CB_Modal_Value"><span>${appData.rec_calc_discount}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Dealer</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_dealer_name}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Origination Fee</h5></td>
                                            <td className="CB_Modal_Value"><span>${parseFloat(appData.rec_orig_fee).toFixed(2)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>USAC Branch</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_dealer_branch}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5 className="dealer_proceeds"><strong>Dealer Proceeds</strong></h5></td>
                                            <td className="CB_Modal_Value_DP"><span className="CB_Modal_Value_DP">${numberWithCommas(appData.rec_dealer_proceeds)}</span></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} rowSpan={1} className="VI_Header"><h4>Vehicle Information</h4></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Payment Frequency</h5></td>
                                            <td className="CB_Modal_Value"><span>{appData.rec_payment_freq}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>New/Used</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_year}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Maximum # of Payments</h5></td>
                                            <td className="CB_Modal_Value"><span>{appData.rec_term_months}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Make</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_make}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Maximum Payment</h5></td>
                                            <td className="CB_Modal_Value"><span>${appData.rec_payment}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Model</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_model}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Minimum Down Payment</h5></td>
                                            <td className="CB_Modal_Value"><span>${appData.req_cash_down}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Trim</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_trim}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Net Trade In</h5></td>
                                            <td rowSpan={1} className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_net_trade)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>VIN #</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_vin}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Rate</h5></td>
                                            <td rowSpan={1} className="CB_Modal_Value"><span>{appData.rec_buyrate}%</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Mileage</h5></td>
                                            <td><span className="CB_Modal_Item">{numberWithCommas(appData.rec_veh_mileage)} miles</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5 className="Analyst_Header">Analyst</h5></td>
                                            <td className="Analyst_Item"><span className="Analyst_Item">{appData.rec_analyst_fullname}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className="Remind"><span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;REMINDERS</span>: &nbsp;</span></p>

                                <ul>
                                    <li className="Remind_item"><span>Title and assignment section of the retail installment contract must refer&nbsp;to<span className="Remind_item_color"> U.S. Auto Credit Purchasing Center, LLC</span>.</span></li>
                                    <li className="Remind_item"><span>All lienholder information should be addressed to&nbsp;</span> <span className="Remind_item_color"><span>P.O. Box 57545, Jacksonville, FL, &nbsp;32241</span>.</span></li>
                                    <li className="Remind_item"><span>Please send all closing documents to&nbsp;<span className="Remind_item_color">8120 Nations Way, STE 208,&nbsp;Jacksonville, FL &nbsp;32256</span>.&nbsp;</span></li>
                                </ul>

                                <p className="Remind_item"><span>The following is a list of our ELT # for those of you in an ELT state:</span></p>

                                <table align="center" className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="Remind_item"><span>Florida: 232767767</span></td>
                                            <td className="Remind_item"><span>North Carolina: 41401159</span></td>
                                            <td className="Remind_item"><span>Virginia: ACP01</span></td>
                                            <td className="Remind_item"><span>Ohio: E09100</span></td>
                                        </tr>
                                        <tr>
                                            <td className="Remind_item"><span>Georgia: 1106989358</span></td>
                                            <td className="Remind_item"><span>South Carolina: 34127734</span></td>
                                            <td className="Remind_item"><span>Maryland: 6781</span></td>
                                            <td className="Remind_item"><span>Texas: 27364763300&nbsp;</span></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p>&nbsp;</p>

                                <table align="center" className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="Stip_head"><h5>Stipulations</h5></td>
                                        </tr>
                                        {dealStips.map((dealStip, index) => (
                                            <tr key={index}>
                                                <td><span className="Stip_item"></span>{dealStip}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>

                                <table align="center" className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="Notes_Head"><span>U.S. Auto Credit Notes to Dealer</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {dealerNotes.map((dealerNote, index) => (
                                                    <p key={index}><span className="Note_item">{dealerNote}</span></p>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p>&nbsp;</p>

                                <p>&nbsp;</p>

                                <p>&nbsp;</p>

                                <div>&nbsp;</div>

                                <div>
                                    <table align="center" className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td><span className="Contact">Underwriting Phone #</span></td>
                                                <td><span className="Contact">877-280-9267</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="Contact">FAX YOUR LOAN PACKET to</span></td>
                                                <td><span className="Contact">888-769-3390</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Decline Callback Modal */}
            <div className="modal fade" id="decline_callback_modal" tabIndex={-1} role="dialog" aria-labelledby="decline_callback_modal" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Call Back</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive">
                                <p className="CB_Header">U.S. AUTO CREDIT PURCHASING CENTER, LLC</p>
                                <table className=" table table-striped table-bordered ">
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} className="text-center"><span style={{ fontSize: "12px" }}>Customer Information</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Applicant</span></td>
                                            <td><span className="Contact">{appData.rec_app_first_name} {appData.rec_app_last_name}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Co-Applicant</span></td>
                                            <td><span className="Contact">{appData.rec_coapp_first_name} {appData.rec_coapp_last_name}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Application #</span></td>
                                            <td><span className="Contact">{appData.rec_application_number}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Application Date</span></td>
                                            <td><span className="Contact">{appData.app_received_date}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Decision Date</span></td>
                                            <td><span className="Contact">{appData.app_decision_date}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Status</span></td>
                                            <td><span className="Contact">{appData.rec_app_status}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Dealer</span></td>
                                            <td><span className="Contact">{appData.rec_dealer_name}</span></td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="Stip_head"><span className="Contact">Vehicle Information</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">New/Used</span></td>
                                            <td><span className="Contact">{appData.rec_veh_year}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Make</span></td>
                                            <td><span className="Contact">{appData.rec_veh_make}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Model</span></td>
                                            <td><span className="Contact">{appData.rec_veh_model}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Trim</span></td>
                                            <td><span className="Contact">{appData.rec_veh_trim}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">VIN#</span></td>
                                            <td><span className="Contact">{appData.rec_vin}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="Contact">Mileage</span></td>
                                            <td><span className="Contact">{numberWithCommas(appData.rec_veh_mileage)} miles</span></td>
                                        </tr>

                                    </tbody>
                                </table>

                                <table align="center" className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="Notes_Head" scope="col"><span>U.S. Auto Credit Notes to Dealer</span></td>
                                        </tr>
                                        <tr>
                                            <td><span style={{fontSize: "12px"}}>This application has been {appData.rec_app_status}. </span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {dealerNotes.map((dealerNote, index) => (
                                                    <p key={index}><span className="Note_item">{dealerNote}</span></p>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>

                                <div>
                                    <table align="center" className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="text-center" colSpan={2} scope="col"><span className="Contact">Analyst Information</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><span className="Contact">Name</span></td>
                                                <td><span className="Contact">{appData.rec_analyst_fullname}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="Contact">Underwriting Phone #</span></td>
                                                <td><span className="Contact">877-280-9267</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="Contact">Email:</span></td>
                                                <td><span className="Contact">Underwriting@usautocreditcorp.com</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Stip Sheet Modal */}
            <div className="modal fade" id="stip_sheet_modal" tabIndex={-1} role="dialog" aria-labelledby="stip_sheet_modal" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Stip Sheet</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive">
                                <p className="CB_Header">U.S. AUTO CREDIT PURCHASING CENTER, LLC</p>
                                <p className="St_sub_Header">The following is a detailed list of missing stipulations that are required to be cleared for US Auto Credit to release funds on the purchase of the retail installment sales contract outlined&nbsp;below. &nbsp;Please contact US Auto Credit with any ?&#39;s or concerns. &nbsp;Thanks!</p>
                                <table className="table table-striped table-bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} rowSpan={1} className="CI_Modal"><h4>Customer Information</h4></td>
                                            <td>&nbsp;</td>
                                            <td colSpan={2} rowSpan={1} className="FI_Modal"><h4>Approved Financing Information</h4></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Applicant</h5></td>
                                            <td><span className="CB_Modal_Item" >{appData.rec_app_first_name} {appData.rec_app_last_name}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Max Advance</h5></td>
                                            <td className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_unpaid_balance)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Co-Applicant, if any</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_coapp_first_name} {appData.rec_coapp_last_name}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>GAP Protection</h5></td>
                                            <td className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_gap)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Application #</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_application_number}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>FL Doc Stamp Tax, if any</h5></td>
                                            <td className="CB_Modal_Value"><span>$&nbsp;${numberWithCommas(appData.rec_credit_life_ins)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Status</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_app_status}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Total Amount Financed</h5></td>
                                            <td className="CB_Modal_Value"><span>${numberWithCommas(appData.rec_est_amt_financed)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Dealer</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_dealer_name}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Discount/Lender Fee</h5></td>
                                            <td className="CB_Modal_Value"><span>${appData.rec_calc_discount}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Dealer Phone #</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_dealer_phone}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Origination Fee</h5></td>
                                            <td className="CB_Modal_Value"><span>${appData.rec_orig_fee ? parseFloat(appData.rec_orig_fee).toFixed(2) : '0.00'}</span></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} rowSpan={1} className="VI_Header"><h4>Vehicle Information</h4></td>
                                            <td>&nbsp;</td>
                                            <td><h5 className="dealer_proceeds"><strong>Dealer Proceeds</strong></h5></td>
                                            <td className="CB_Modal_Value_DP"><span className="CB_Modal_Value_DP">${numberWithCommas(appData.rec_dealer_proceeds)}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>New/Used</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_year}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Maximum # of Payments</h5></td>
                                            <td className="CB_Modal_Value"><span>{appData.rec_term_months}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Make</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_make}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Maximum Payment</h5></td>
                                            <td className="CB_Modal_Value"><span>${appData.rec_payment}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Model</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_model}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Minimum Down Payment</h5></td>
                                            <td className="CB_Modal_Value"><span>${appData.req_cash_down}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Trim</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_veh_trim}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Rate</h5></td>
                                            <td rowSpan={1} className="CB_Modal_Value"><span>{appData.rec_buyrate}%</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>VIN #</h5></td>
                                            <td><span className="CB_Modal_Item">{appData.rec_vin}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Payment Frequency</h5></td>
                                            <td rowSpan={1} className="CB_Modal_Value"><span>{appData.rec_payment_freq}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Mileage</h5></td>
                                            <td><span className="CB_Modal_Item">{numberWithCommas(appData.rec_veh_mileage)}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Package Received Date</h5></td>
                                            <td rowSpan={1} className="CB_Modal_Value"><span>{appData.cr_received_date && appData.cr_received_date.includes('NaN') ? '---' : appData.cr_received_date}</span></td>
                                        </tr>
                                        <tr>
                                            <td><h5>Last Verification Update</h5></td>
                                            <td><span className="CB_Modal_Item">{''}</span></td>
                                            <td>&nbsp;</td>
                                            <td><h5>Initial Stip Date</h5></td>
                                            <td className="CB_Modal_Value"><span>{appData.vi_entry_date && appData.vi_entry_date.includes('NaN') ? '---' : appData.vi_entry_date}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className="St_Header">
                                    Missing stips must be recieved by <b>{appData.vi_end_date && appData.vi_end_date.includes('NaN') ? '[Contact US Auto Credit for the due date]' : appData.vi_end_date}</b> or package may be returned.
                                </p>
                                <div className="ST__Value" style={{ fontWeight: 'bold' }}>
                                    <ul>
                                        {dealStips.map((dealStip, index) => {
                                            if (String(dealStip).includes('|')) {
                                                var stipName = String(dealStip).split('|')[0];
                                                var stipStatus = String(dealStip).split('|')[1];
                                                var stipType = String(dealStip).split('|')[2];
                                                if (stipStatus === 'Failed' && stipType === 'UW') {
                                                    return <li key={index}>{stipName}</li>;
                                                }
                                            }
                                        })}
                                    </ul>
                                </div>
                                <p className="St_Header"><span><span>US Auto Credit still needs to complete the following verification tasks:</span></span></p>
                                <div className="ST__Value">
                                    <ul>
                                        {dealStips.map((dealStip, index) => {
                                            if (String(dealStip).includes('|')) {
                                                var stipName = String(dealStip).split('|')[0];
                                                var stipStatus = String(dealStip).split('|')[1];
                                                var stipType = String(dealStip).split('|')[2];
                                                if ((stipStatus === 'Failed' || stipStatus === 'Incomplete') && stipType === 'VF') {
                                                    return <li key={index}>{stipName}</li>;
                                                }
                                            }
                                        })}
                                    </ul>
                                </div>
                                <p className="St_Header"><span><span>Additional comments:</span></span></p>
                                {/* <p className="ST__Value"><span><strong>{'appData.ric_vf_comments1'}</strong></span></p>
                                <p className="ST__Value"><span ><strong>{'appData.ric_vf_comments2'}</strong></span></p>
                                <p className="ST__Value"><span><strong>{'appData.ric_vf_comments3'}</strong></span></p>
                                <p className="ST__Value"><span><strong>{'appData.ric_vf_comments4'}</strong></span></p>
                                <p className="ST__Value"><span><strong>{'appData.ric_vf_comments5'}</strong></span></p> */}
                                <p className="St_Header"><span><span>Allow up to 24 hour response time for updated stips.&nbsp;</span></span></p>
                                <div>
                                    <table align="center" className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td><span className="Contact">Underwriting Phone #</span></td>
                                                <td><span className="Contact">877-280-9267</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="Contact">FAX YOUR LOAN PACKET to</span></td>
                                                <td><span className="Contact">888-769-3390</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Customer Info Modal */}
            <div className="modal fade" id="customer_modal" tabIndex={-1} role="dialog" aria-labelledby="customer_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                {/* Applicant Info */}
                                <div className="col-xl-6">
                                    <div className="card mb-1">
                                        <div style={{ height: "50px" }} className="card-header">Applicant Info</div>
                                        <div className="card-body">
                                            <div className="row gx-3 mb-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_app_first_name">First Name</label>
                                                    <input id="rec_app_first_name" name="rec_app_first_name" className="form-control input_height" type="text" value={appData.rec_app_first_name} onChange={handleChange} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_app_last_name">Last Name</label>
                                                    <input id="rec_app_last_name" name="rec_app_last_name" className="form-control input_height" type="text" value={appData.rec_app_last_name} onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-1">
                                        <div style={{ height: "50px" }} className="card-header">Applicant Address</div>
                                        <div className="card-body">
                                            <div className="row gx-3">
                                                <div className="col-md-3">
                                                    <label className="small mb-1" htmlFor="rec_app_streetno">Street Number</label>
                                                    <input id="rec_app_streetno" name="rec_app_streetno" className="form-control input_height" type="text" value={appData.rec_app_streetno} onChange={handleChange} autoComplete="off" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_app_streetname">Street Name</label>
                                                    <input id="rec_app_streetname" name="rec_app_streetname" className="form-control input_height" type="text" value={appData.rec_app_streetname} onChange={handleChange} autoComplete="off" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mb-1" htmlFor="rec_app_aptno">Apt/Suite</label>
                                                    <input id="rec_app_aptno" name="rec_app_aptno" className="form-control input_height" type="text" value={appData.rec_app_aptno ? appData.rec_app_aptno : ''} onChange={handleChange} autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row gx-3">
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_app_city">City</label>
                                                    <input id="rec_app_city" name="rec_app_city" className="form-control input_height" type="text" value={appData.rec_app_city ? appData.rec_app_city : ''} onChange={handleChange} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_app_state">State</label>
                                                    {/* <input id="rec_app_state" name="rec_app_state" className="form-control input_height" type="text" value={appData.rec_app_state ? appData.rec_app_state : ''} onChange={handleChange} /> */}
                                                    <select id="rec_app_state" name="rec_app_state" className="form-select dropdown" aria-label="Default select example" value={appData.rec_app_state ? appData.rec_app_state : ''} onChange={handleSelectChange}>
                                                        <option value="">- Select - </option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_app_zip">Zip</label>
                                                    <input id="rec_app_zip" name="rec_app_zip" className="form-control input_height" type="text" value={appData.rec_app_zip ? appData.rec_app_zip : ''} onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="row gx-3 ">
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_app_addr_years">Years at Address</label>
                                                    <input id="rec_app_addr_years" name="rec_app_addr_years" className="form-control input_height" type="number" min="0" value={appData.rec_app_addr_years ? appData.rec_app_addr_years : ''} onChange={handleChange} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_app_addr_months">Months at Address</label>
                                                    <input id="rec_app_addr_months" name="rec_app_addr_months" className="form-control input_height" type="number" min="0" value={appData.rec_app_addr_months ? appData.rec_app_addr_months : ''} onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-1">
                                        <div style={{ height: "50px" }} className="card-header">Applicant Employer</div>
                                        <div className="card-body">
                                            <form>
                                                <input type="hidden" value="autocompleteworkaround" />
                                                <div className="row gx-3">
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" htmlFor="rec_app_emp_name">Primary Employer Name</label>
                                                        <input id="rec_app_emp_name" name="rec_app_emp_name" className="form-control input_height" type="text" value={appData.rec_app_emp_name ? appData.rec_app_emp_name : ''} onChange={handleChange} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" htmlFor="rec_app_income">Primary Income</label>
                                                        <input id="rec_app_income" name="rec_app_income" className="form-control input_height" type="number" min="0" value={appData.rec_app_income ? appData.rec_app_income : ''} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" htmlFor="rec_app_emp_years">Years on Job</label>
                                                        <input id="rec_app_emp_years" name="rec_app_emp_years" className="form-control input_height" type="number" min="0" value={appData.rec_app_emp_years ? appData.rec_app_emp_years : ''} onChange={handleChange} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" htmlFor="rec_app_emp_months">Months on Job</label>
                                                        <input id="rec_app_emp_months" name="rec_app_emp_months" className="form-control input_height" type="number" min="0" value={appData.rec_app_emp_months ? appData.rec_app_emp_months : ''} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" htmlFor="rec_app_other_income_source">Other Income Source</label>
                                                        <input id="rec_app_other_income_source" name="rec_app_other_income_source" className="form-control input_height" type="text" value={appData.rec_app_other_income_source ? appData.rec_app_other_income_source : ''} onChange={handleChange} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" htmlFor="rec_app_other_income">Other Income</label>
                                                        <input id="rec_app_other_income" name="rec_app_other_income" className="form-control input_height" type="number" min="0" value={appData.rec_app_other_income ? appData.rec_app_other_income : ''} onChange={handleChange} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                {/* Co-Applicant Info */}
                                <div className="col-xl-6">
                                    <div className="card mb-1">
                                        <div className="card-header">Co-Applicant Info {!appData.rec_coapp_last_name ? ' - call us at (877) 280-9267 to add a co-applicant': ''}</div>
                                        <div className="card-body">
                                            <div className="row gx-3 mb-2">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_first_name">First Name</label>
                                                    <input id="rec_coapp_first_name" name="rec_coapp_first_name" className="form-control input_height" type="text" value={appData.rec_coapp_first_name ? appData.rec_coapp_first_name : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_last_name">Last Name</label>
                                                    <input id="rec_coapp_last_name" name="rec_coapp_last_name" className="form-control input_height" type="text" value={appData.rec_coapp_last_name ? appData.rec_coapp_last_name : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-1 ">
                                        <div style={{ height: "50px" }} className="card-header">Co-Applicant Address</div>
                                        <div className="card-body">
                                            <div className="row gx-3">
                                                <div className="col-md-3">
                                                    <label className="small mb-1" htmlFor="inputFirstName">Street Number</label>
                                                    <input id="rec_coapp_streetno" name="rec_coapp_streetno" className="form-control input_height" type="text" value={appData.rec_coapp_streetno ? appData.rec_coapp_streetno : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} autoComplete="off" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_streetname">Street Name</label>
                                                    <input id="rec_coapp_streetname" name="rec_coapp_streetname" className="form-control input_height" type="text" value={appData.rec_coapp_streetname ? appData.rec_coapp_streetname : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} autoComplete="off" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="small mb-1" htmlFor="rec_coapp_aptno">Apt/Suite</label>
                                                    <input id="rec_coapp_aptno" name="rec_coapp_aptno" className="form-control input_height" type="text" value={appData.rec_coapp_aptno ? appData.rec_coapp_aptno : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="row gx-3">
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_coapp_city">City</label>
                                                    <input id="rec_coapp_city" name="rec_coapp_city" className="form-control input_height" type="text" value={appData.rec_coapp_city ? appData.rec_coapp_city : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_coapp_state">State</label>
                                                    <select id="rec_coapp_state" name="rec_coapp_state" className="form-select dropdown" aria-label="Default select example" value={appData.rec_coapp_state ? appData.rec_coapp_state : ''} onChange={handleSelectChange} disabled={!appData.rec_coapp_last_name}>
                                                        <option value="">- Select - </option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_coapp_zip">Zip</label>
                                                    <input id="rec_coapp_zip" name="rec_coapp_zip" className="form-control input_height" type="text" value={appData.rec_coapp_zip ? appData.rec_coapp_zip : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                            </div>
                                            <div className="row gx-3">
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_coapp_addr_years">Years at Address</label>
                                                    <input id="rec_coapp_addr_years" name="rec_coapp_addr_years" className="form-control input_height" type="number" min="0" value={appData.rec_coapp_addr_years ? appData.rec_coapp_addr_years : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="small mb-1" htmlFor="rec_coapp_addr_months">Months at Address</label>
                                                    <input id="rec_coapp_addr_months" name="rec_coapp_addr_months" className="form-control input_height" type="number" min="0" value={appData.rec_coapp_addr_months ? appData.rec_coapp_addr_months : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-1">
                                        <div style={{ height: "50px" }} className="card-header">Co-Applicant Employer</div>
                                        <div className="card-body">
                                            <div className="row gx-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_emp_name">Primary Employer Name</label>
                                                    <input id="rec_coapp_emp_name" name="rec_coapp_emp_name" className="form-control input_height" type="text" value={appData.rec_coapp_emp_name ? appData.rec_coapp_emp_name : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_income">Primary Income</label>
                                                    <input id="rec_coapp_income" name="rec_coapp_income" className="form-control input_height" type="number" min="0" value={appData.rec_coapp_income ? appData.rec_coapp_income : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                            </div>
                                            <div className="row gx-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_emp_years">Years on Job</label>
                                                    <input id="rec_coapp_emp_years" name="rec_coapp_emp_years" className="form-control input_height" type="number" min="0" value={appData.rec_coapp_emp_years ? appData.rec_coapp_emp_years : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_emp_months">Months on Job</label>
                                                    <input id="rec_coapp_emp_months" name="rec_coapp_emp_months" className="form-control input_height" type="number" min="0" value={appData.rec_coapp_emp_months ? appData.rec_coapp_emp_months : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                            </div>
                                            <div className="row gx-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_other_income_source">Other Income Source</label>
                                                    <input id="rec_coapp_other_income_source" name="rec_coapp_other_income_source" className="form-control input_height" type="text" value={appData.rec_coapp_other_income_source ? appData.rec_coapp_other_income_source : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="rec_coapp_other_income">Other Income</label>
                                                    <input id="rec_coapp_other_income" name="rec_coapp_other_income" className="form-control input_height" type="number" min="0" value={appData.rec_coapp_other_income ? appData.rec_coapp_other_income : ''} onChange={handleChange} disabled={!appData.rec_coapp_last_name} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success" type="button" data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Post-Submit Modal */}
            <div className="modal fade" id="post_submit_modal" tabIndex={-1} role="dialog" aria-labelledby="post_submit_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img src="/images/green-check-mark.png" alt="check" style={{ marginLeft: '35px', marginRight: '15px' }} width="100px" />
                            <h6 className="modal-title">
                                {serverMessage && (serverMessage.includes('Approved') || serverMessage.includes('Conditioned') || serverMessage.includes('AD') || serverMessage.includes('Declined')) ?
                                    'You have received a response from our underwriting team.' :
                                    serverMessage && serverMessage.includes('Rehash') ?
                                        'We need a little more time to give your application the attention it deserves. We will contact you shortly.' :
                                        'Rehash submitted. We are now reviewing your application and giving it the attention you deserve.'}
                            </h6>
                            {/* <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        {serverMessage && (serverMessage.includes('Approved') || serverMessage.includes('Conditioned') || serverMessage.includes('AD') || serverMessage.includes('Declined')) ?
                            <div className="modal-body text-center">Close this dialog box to view the response.</div> : ''}
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="button" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Previous Structures modal */}
            <div className="modal fade" id="structures" tabIndex={-1} role="dialog" aria-labelledby="structures" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Previous Structures</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive Pre_structure">
                                <table className="table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Date/Time</th>
                                            <th scope="col">Vehicle</th>
                                            <th scope="col">Payment Amount</th>
                                            <th scope="col">Net Check</th>
                                            <th scope="col">Update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>10/31/2022 10:12am</td>
                                            <td>2010 Honda Accord</td>
                                            <td>$435.01</td>
                                            <td>$12,225.00</td>
                                            <td><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Update</button></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>10/31/2022 10:12am</td>
                                            <td>2010 Honda Accord</td>
                                            <td>$435.01</td>
                                            <td>$12,225.00</td>
                                            <td><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Update</button></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>10/31/2022 10:12am</td>
                                            <td>2010 Honda Accord</td>
                                            <td>$435.01</td>
                                            <td>$12,225.00</td>
                                            <td><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Update</button></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">4</th>
                                            <td>10/31/2022 10:12am</td>
                                            <td>2010 Honda Accord</td>
                                            <td>$435.01</td>
                                            <td>$12,225.00</td>
                                            <td><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Update</button></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">5</th>
                                            <td>10/31/2022 10:12am</td>
                                            <td>2010 Honda Accord</td>
                                            <td>$435.01</td>
                                            <td>$12,225.00</td>
                                            <td><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Update</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer"><button className="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button></div>
                    </div>
                </div>
            </div>
            
            {/* Vehicle Info Modal */}
            <div className="modal fade" id="vehicle_modal" tabIndex={-1} role="dialog" aria-labelledby="vehicle_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xl-6">
                                    {/* Account details card */}
                                    <div className="card mb-4">
                                        <div className="card-header">Current Vehicle</div>
                                        <div className="card-body">
                                            <form>
                                                <input type="hidden" value="autocompleteworkaround" />
                                                <div className="row gx-3 mb-3">
                                                    <div className="col-md-12">
                                                        <label className="large mb-1 vehicle_label" htmlFor="inputVin">VIN</label>
                                                        <p>{currentVin}</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="large mb-1 vehicle_label" htmlFor="inputMileage">Mileage</label>
                                                        <p>{numberWithCommas(currentMileage)}</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="large  mb-1 vehicle_label" htmlFor="inputCollateral">Vehicle</label>
                                                        <p>{currentVehicleDescription}</p>
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <label className="large  mb-1 vehicle_label" htmlFor="inputMake">Make</label>
                                                        <p>HYUNDAI</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="large  mb-1 vehicle_label" htmlFor="inputModel">Model</label>
                                                        <p>SONATA</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="large  mb-1 vehicle_label" htmlFor="inputTrim">Trim</label>
                                                        <p>SEDAN 4D SE 2.0L I4 TURBO</p>
                                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card mb-4">
                                        <div className="card-header">New Vehicle</div>
                                        <div className="card-body">
                                            <form>
                                                <input type="hidden" value="autocompleteworkaround" />
                                                <div className="row gx-3 mb-3">
                                                    <div className="col-md-12">
                                                        <label className="small mb-1" htmlFor="rec_vin">VIN</label>
                                                        <input id="rec_vin" name="rec_vin" type="text" className="form-control app_input input_height" maxLength={17} onChange={handleChange} />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="small mb-1" htmlFor="rec_veh_mileage">Mileage</label>
                                                        <input id="rec_veh_mileage" name="rec_veh_mileage" type="number" min="0" className="form-control app_input input_height" maxLength={6} onChange={handleChange} />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="small mb-1" htmlFor="dotYear">Year</label>
                                                        <input className="form-control app_input input_height" id="dotYear" type="text" value={newVehicleYear ? newVehicleYear : ""} readOnly />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="small mb-1" htmlFor="dotMake">Make</label>
                                                        <input className="form-control app_input input_height" id="dotMake" type="text" value={newVehicleMake ? newVehicleMake : ""} readOnly />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="small mb-1" htmlFor="dotModel">Model</label>
                                                        <input className="form-control app_input input_height" id="dotModel" type="text" value={newVehicleModel ? newVehicleModel : ""} readOnly />
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <label className="small mb-1" htmlFor="inputTrim">Trim</label>
                                                        <input className="form-control app_input input_height" id="inputTrim" placeholder="Will be determined by the VIN" readOnly />
                                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {modifiedVehicle ?
                                <div style={{ color: 'blue', marginRight: '20px' }}>
                                    You have changed the vehicle. Please make sure to adjust the<br />Sale Price and click the Rehash button after you exit this dialog box.
                                </div> :
                                ''}
                            <button className="btn btn-success" type="button" data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Chat Modal */}
            <div className="modal fade" id="comments_modal" tabIndex={-1} role="dialog" aria-labelledby="comments_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-12 col-lg-10 col-xl-12">
                                        <div className="card overflow-auto" style={{ maxHeight: "500px", display: "flex", flexDirection: "column-reverse" }}>
                                            <div className="card-body">
                                                {chatMessages.map((chatMessage, index) => (
                                                    <div key={index} style={{ maxHeight: "600px" }}>
                                                        <div className="d-flex flex-start align-items-center last-child" style={{ animation: "scrollToBottom 0.01s" }} >
                                                            <div>
                                                                <h6 className="fw-bold text-primary mb-1">{chatMessage.first_name} {chatMessage.last_name}</h6>
                                                                <p className="text-muted small mb-0">{chatMessage.date_time}</p>
                                                            </div>
                                                        </div>
                                                        <p className="mt-3 mb-4 pb-2">
                                                            {chatMessage.message}
                                                        </p>
                                                        <hr />
                                                    </div>
                                                ))}
                                                <div ref={commentsContent} />
                                            </div>
                                        </div>
                                        <div className="card-footer py-3 border-0" style={{ backgroundColor: "#f8f9fa" }}>
                                            <div className="d-flex flex-start w-100">
                                                <div className="form-outline w-100">
                                                    <textarea className="form-control" id="newChatMessage" name="newChatMessage" onChange={handleTextAreaChange} value={postedNewChatMessage ? '' : newChatMessage} rows={4} style={{ background: "#fff" }} autoComplete="off" autoFocus></textarea>
                                                    <label className="form-label" htmlFor="newChatMessage">Message</label>
                                                    {chatbotResponse ? <div><b>Glynn the Chatbot</b>{chatbotResponse}</div> : ''}
                                                </div>
                                            </div>
                                            <div className="float-end mt-4 pt-1">
                                                <button type="button" className="btn btn-primary" onClick={() => upsertChatMessage()}>Post Message</button> &nbsp;&nbsp;
                                                <button type="button" className="btn " data-bs-dismiss="modal" style={{ width: '120px' , backgroundColor: "#808080" , color: 'white'}}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Us Modal */}
            <div className="modal fade" id="contact_us_modal" tabIndex={-1} role="dialog" aria-labelledby="contact_us_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card mb-4">
                                        <div style={{ height: "50px" }} className="card-header">Contact Info</div>
                                        <div className="card-body">
                                            <div className="col-12">
                                                <ul className="list-unstyled">
                                                    <li><strong>Sales</strong></li>
                                                    <li>Phone: 877-280-9267</li>
                                                    <li>Email: <a href="mailto:DealerSupport@usautocreditcorp.com" className="text-primary">DealerSupport@usautocreditcorp.com</a></li>
                                                    <br />
                                                    <li><strong>Underwriting</strong></li>
                                                    <li>Phone: 877-280-9267</li>
                                                    <li>Email: <a href="mailto:Underwriting@usautocreditcorp.com" className="text-primary">Underwriting@usautocreditcorp.com</a></li>
                                                    <br />
                                                    <li><strong>Funding</strong></li>
                                                    <li>Phone: 877-280-9267</li>
                                                    <li>Email: <a href="mailto:Autocreditfunding@usautocreditcorp.com" className="text-primary">Autocreditfunding@usautocreditcorp.com</a></li>
                                                    <br />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card mb-4">
                                        <div style={{ height: "50px" }} className="card-header">Funding Info</div>
                                        <div className="card-body">
                                            <ul className="list-unstyled">
                                                <li><strong>Loss Payee (Insurance) Information</strong></li>
                                                <li>US Auto Credit Purchasing Center, LLC</li>
                                                <li>PO Box 421669 Atlanta, GA 30342</li>
                                                <br />
                                                <li><strong>Lien Holder Information</strong></li>
                                                <li>US Auto Credit Purchasing Center, LLC</li>
                                                <li>PO Box 57545 Jacksonville, FL 32241</li>
                                                <br />
                                                <li><strong>Send Original Funding Packages to</strong></li>
                                                <li>Funding Department</li>
                                                <li>8120 Nations Way, STE 208 Jacksonville, FL 32256</li>
                                                <br />
                                                <li><strong>Send Stips and Funding Package Copies to</strong></li>
                                                <li><a href="mailto:Autocreditfunding@usautocreditcorp.com" className="text-primary">Autocreditfunding@usautocreditcorp.com</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
  
export default Rehash;