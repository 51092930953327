import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Activity, BarChart, Bell, Database, LogIn, LogOut, Mail, Menu, Users, UserPlus } from 'react-feather';

const Layout = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [user_id] = useState(localStorage.getItem('user_id'));
    const [first_name] = useState(localStorage.getItem('first_name'));
    const [last_name] = useState(localStorage.getItem('last_name'));
    const navigate = useNavigate();

    useEffect(() => {
        var heimdall = localStorage.getItem('heimdall');
        
        if (heimdall && heimdall.length === 32) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
        
    }, [loggedIn])

    return (
        <>
            <Header />
            <Body />
            <Footer />
        </>
    )

    function Header() {
        return (
            <>
                <nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
                    {/* Sidenav Toggle Button */}
                    <button className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"><Menu /></button>
                    {/* Navbar Brand 
                    Tip:  You can use text or an image for your navbar brand.
                    When using an image, we recommend the SVG format.
                    Dimensions: Maximum height: 32px, maximum width: 240px */}
                    <a href="/">
                        <img src="/images/us-auto-credit-white-logo.png" alt="USAC Logo" height="57px;" />
                    </a>                    
                    <div style={{ fontWeight: "bold", marginLeft: "12px" }}>Dealer Portal</div>

                    {/* Navbar Search Input-->
                    Note:  Visible only on and above the lg breakpoint */}
                    {/* <form className="form-inline me-auto d-none d-lg-block me-3">
                    <div className="input-group input-group-joined input-group-solid">
                        <input className="form-control pe-0" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-text"><Search /></div>
                    </div>
                </form> */}
                    {/* Navbar Items */}
                    
                    <ul className="navbar-nav align-items-center ms-auto">
                        {
                            String(user_id).includes('@usautocreditcorp.com') ?
                                <li className="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications" hidden={!String(user_id).includes('@usautocreditcorp.com')} title="Switch dealers" style={{ display: "none", visibility: "hidden" }}>
                                    <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownMessages" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Database /></a>
                                    <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownMessages">
                                        <h6 className="dropdown-header dropdown-notifications-header">
                                            <span className="me-2"><Database /></span>
                                            Dealerships
                                        </h6>
                                        <a className="dropdown-item dropdown-notifications-item" href="#" onClick={() => ChangeDealer('33976', '12K & Under Motors')}>
                                            <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/red-ferrari.jpg" alt="Desc" />
                                            <div className="dropdown-notifications-item-content">
                                                <div className="dropdown-notifications-item-content-text">12K & Under Motors</div>
                                                <div className="dropdown-notifications-item-content-details">33976</div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item dropdown-notifications-item" href="#" onClick={() => ChangeDealer('45773', 'Test Dealer')}>
                                            <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/red-ferrari.jpg" alt="Desc" />
                                            <div className="dropdown-notifications-item-content">
                                                <div className="dropdown-notifications-item-content-text">Test Dealer Prod</div>
                                                <div className="dropdown-notifications-item-content-details">45773</div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item dropdown-notifications-item" href="#" onClick={() => ChangeDealer('29272', 'Test Dealer')}>
                                            <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/red-ferrari.jpg" alt="Desc" />
                                            <div className="dropdown-notifications-item-content">
                                                <div className="dropdown-notifications-item-content-text">Test Dealer QA</div>
                                                <div className="dropdown-notifications-item-content-details">29272</div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item dropdown-notifications-item" href="#" onClick={() => ChangeDealer('34066', 'Duval Ford, LLC')}>
                                            <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/red-ferrari.jpg" alt="Desc" />
                                            <div className="dropdown-notifications-item-content">
                                                <div className="dropdown-notifications-item-content-text">Duval Ford, LLC</div>
                                                <div className="dropdown-notifications-item-content-details">34066</div>
                                            </div>
                                        </a>

                                        <a className="dropdown-item dropdown-notifications-footer" href="/dealers">View All Dealerships</a>
                                    </div>
                                </li> : ""
                        }

                        {false ?
                            <li className="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications">
                                <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownMessages" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Mail /></a>
                                <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownMessages">
                                    <h6 className="dropdown-header dropdown-notifications-header">
                                        <span className="me-2"><Mail /></span>
                                        Message Center
                                    </h6>
                                    {/* Example Message 1 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/profile-2.png" alt="Desc" />
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                            <div className="dropdown-notifications-item-content-details">Thomas Wilcox · 58m</div>
                                        </div>
                                    </a>
                                    {/* Example Message 2 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/profile-3.png" alt="Desc" />
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                            <div className="dropdown-notifications-item-content-details">Emily Fowler · 2d</div>
                                        </div>
                                    </a>
                                    {/* Example Message 3 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/profile-4.png" alt="Desc" />
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                            <div className="dropdown-notifications-item-content-details">Marshall Rosencrantz · 3d</div>
                                        </div>
                                    </a>
                                    {/* Example Message 4 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <img className="dropdown-notifications-item-img" src="/assets/img/illustrations/profiles/profile-5.png" alt="Desc" />
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                            <div className="dropdown-notifications-item-content-details">Colby Newton · 3d</div>
                                        </div>
                                    </a>
                                    {/* Footer Link */}
                                    <a className="dropdown-item dropdown-notifications-footer" href="#!">Read All Messages</a>
                                </div>
                            </li> : ""
                        }

                        {false ?
                            <li className="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications">
                                <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownAlerts" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Bell /></a>
                                <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownAlerts">
                                    <h6 className="dropdown-header dropdown-notifications-header">
                                        <span className="me-2"><Bell /></span>
                                        Alerts Center
                                    </h6>
                                    {/* Example Alert 1 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <div className="dropdown-notifications-item-icon bg-warning"><Activity /></div>
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-details">December 29, 2021</div>
                                            <div className="dropdown-notifications-item-content-text">This is an alert message. It's nothing serious, but it requires your attention.</div>
                                        </div>
                                    </a>
                                    {/* Example Alert 2 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <div className="dropdown-notifications-item-icon bg-info"><BarChart /></div>
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-details">December 22, 2021</div>
                                            <div className="dropdown-notifications-item-content-text">A new monthly report is ready. Click here to view!</div>
                                        </div>
                                    </a>
                                    {/* Example Alert 3 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <div className="dropdown-notifications-item-icon bg-danger"><i className="fas fa-exclamation-triangle"></i></div>
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-details">December 8, 2021</div>
                                            <div className="dropdown-notifications-item-content-text">Critical system failure, systems shutting down.</div>
                                        </div>
                                    </a>
                                    {/* Example Alert 4 */}
                                    <a className="dropdown-item dropdown-notifications-item" href="#!">
                                        <div className="dropdown-notifications-item-icon bg-success"><UserPlus /></div>
                                        <div className="dropdown-notifications-item-content">
                                            <div className="dropdown-notifications-item-content-details">December 2, 2021</div>
                                            <div className="dropdown-notifications-item-content-text">New user request. Woody has requested access to the organization.</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item dropdown-notifications-footer" href="#!">View All Alerts</a>
                                </div>
                            </li> : ""
                        }

                        {/* User Dropdown */}
                        <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
                            <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="img-fluid" src="/assets/img/illustrations/profiles/red-ferrari.jpg" alt="Desc" /></a>
                            <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                                <h6 className="dropdown-header d-flex align-items-center">
                                    <img className="dropdown-user-img" src="/assets/img/illustrations/profiles/red-ferrari.jpg" alt="Desc" />
                                    <div className="dropdown-user-details">
                                        <div className="dropdown-user-details-name">{user_id ? first_name + " " + last_name : "Please log in."}</div>
                                        <div className="dropdown-user-details-email">{user_id}</div>
                                    </div>
                                </h6>
                                {user_id ? <div className="dropdown-divider"></div> : ""} 
                                {/* <a className="dropdown-item" href="#">
                                    <div className="dropdown-item-icon"><Settings /></div>Account
                                </a> */}
                                <a className="dropdown-item" href="#" onClick={Logout} hidden={!loggedIn}>
                                    <div className="dropdown-item-icon"><LogOut /></div>Logout
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </>
        )
    }

    function Body() {
        return (
            <>
                <div id="layoutSidenav">
                    <div id="layoutSidenav_nav">
                        {/* Side Nav */}
                        <nav className="sidenav shadow-right sidenav-light">
                            <div className="sidenav-menu">
                                <div className="nav accordion" id="accordionSidenav">
                                    {/* Sidenav Menu Heading (Account)
                                    Note:  Visible only on and above the sm breakpoint */}
                                    <div className="sidenav-menu-heading d-sm-none">Account</div>
                                    {/* Sidenav Link (Alerts)
                                    Note:  Visible only on and above the sm breakpoint */}
                                    <a className="nav-link d-sm-none" href="#!">
                                        <div className="nav-link-icon"><Bell /></div>
                                        Alerts
                                        <span className="badge bg-warning-soft text-warning ms-auto">4 New!</span>
                                    </a>
                                    {/* Sidenav Link (Messages)
                                    Note:  Visible only on and above the sm breakpoint-->*/}
                                    <a className="nav-link d-sm-none" href="#!">
                                        <div className="nav-link-icon"><Mail /></div>
                                        Messages
                                        <span className="badge bg-success-soft text-success ms-auto">2 New!</span>
                                    </a>

                                    {/* Sidenav Menu Heading (Core) */}
                                    {/* Sidenav Accordion (Dashboard) */}
                                    {
                                        user_id ?
                                            <>
                                                <div className="sidenav-menu-heading">Main</div>
                                                {/* <a className="nav-link" href="/">
                                                    <div className="nav-link-icon"><Activity /></div>Home
                                                </a> */}
                                                <Link to="/" className="nav-link">
                                                    <div className="nav-link-icon"><Activity /></div>Home
                                                </Link>
                                            </> : ''
                                    }

                                    {/* Sidenav Heading (Custom) */}
                                    {/* <div className="sidenav-menu-heading">Queues</div> */}
                                    {/* Sidenav Accordion (Pages) */}
                                    {/* <a className="nav-link" href="/#">
                                        <div className="nav-link-icon"><Grid /></div>
                                        Main Queue
                                    </a> */}

                                    {/* Sidenav Accordion (Applications) */}
                                    {/* <a className="nav-link" href="/#">
                                        <div className="nav-link-icon"><Globe /></div>
                                        Funding Queue
                                    </a> */}

                                </div>
                            </div>
                            {/* Sidenav Footer */}
                            {
                                user_id ? 
                                    <div className="sidenav-footer">
                                        <LogIn style={{marginRight: "12px"}} />
                                        <div className="sidenav-footer-content">
                                            <div className="sidenav-footer-subtitle">Logged in as:</div>
                                            <div className="sidenav-footer-title">{first_name} {last_name}</div>
                                        </div>
                                    </div> : ""
                            }
                        </nav>
                    </div>
                    <div id="layoutSidenav_content">
                        {/* Main Page */}
                        <main>
                            <Outlet />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    function Footer() {
        return (
            <>
                <footer className="footer-admin mt-auto footer-light">
                    <div className="container-xl px-4">
                        <div className="row">
                            <div className="col-md-6 small">Copyright &copy; 2023. All Rights Reserved. U.S. Auto Credit.</div>
                            <div className="col-md-6 text-md-end small">
                                <a href="#!">Privacy Policy</a>&nbsp; &middot; &nbsp;<a href="#!">Terms &amp; Conditions</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }

    function ChangeDealer(new_dealer_id: string, new_dealer_name: string) {
        localStorage.setItem('selected_dealer_id', new_dealer_id);
        localStorage.setItem('dealer_name', new_dealer_name);
        navigate('/');
    }

    function Logout() {
        localStorage.setItem('dealer_id', '');
        localStorage.setItem('selected_dealer_id', '');
        localStorage.setItem('dealer_name', '');
        localStorage.setItem('user_id', '');
        localStorage.setItem('heimdall', '');
        localStorage.setItem('first_name', '');
        localStorage.setItem('last_name', '');
        localStorage.setItem('env', '');
        console.log('Logged out');
        navigate('/login');
        window.location.reload();
    }
};

export default Layout;

