import './App.css';
import './Custom.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Rehash from "./pages/Rehash";
import ResetPassword from "./pages/ResetPassword";
import NoPage from "./pages/NoPage";
import { Amplify } from 'aws-amplify';

Amplify.configure({
    Auth: {
        userPoolId: 'us-east-2_aLU0XpQiM', //UserPool ID
        region: 'us-east-2',
        userPoolWebClientId: '1o0lodpkqom1cfljqop3lso33q' //WebClientId
    }
});

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="rehash/:applicationNumber" element={<Rehash />} />
                    <Route path="resetpassword/:token" element={<ResetPassword />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
