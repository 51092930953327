import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import FilterComponent from './FilterComponent';
import { Link, useNavigate } from "react-router-dom";
import './App.css';
import './Custom.css';

const Table = (props) => {
  const navigate = useNavigate();
  const { appData, dealerId, getApplication } = props;

  function getApp(applicationNumber, applicationDealerId, applicationDealerName) {
    localStorage.setItem('selected_dealer_id', applicationDealerId);
    localStorage.setItem('dealer_name', applicationDealerName);
    console.log("Inside function");
    navigate('/rehash/' + applicationNumber);
}

// const Table = (props) => {
  const columns = [
    {
      name: 'Number',
      selector: (row) => row.application_number,
      sortable: true,                       
    },  
    {
      name: 'Dealer',
      selector: (row) => row.rec_dealer_name.substring(0, 12),
      sortable: true,           
      // visible : dealerId && dealerId.includes(',') ,           
    },    
    {
      name: 'Name',
      selector: (row) => row.rec_app_name,
      sortable: true,
      width : '150px',      
    },    
    {
      name: 'VIN',
      selector: (row) => row.rec_vin_value ? row.rec_vin_value.substring(String(row.rec_vin_value).length - 6, String(row.rec_vin_value).length) : '',
      sortable: true,  
      width : '100px',   
           
    },
    {
      name: 'Vehicle',
      selector: (row) => row.rec_vehicle ? row.rec_vehicle.substring(0, 30) : '',
      sortable: true,
      width : '200px',
      
    },
    {
      name: 'Amount Financed',
      selector: (row) => row.rec_est_amt_financed_value,
      sortable: true,   
      width : '150px',   
      sortingFunction: (a, b) => a - b, 
      cell: (row) => row.rec_est_amt_financed,     
    },
    {
      name: 'Net Check',
      selector: (row) => row.rec_dealer_proceeds_value,
      sortable: true,      
      sortingFunction: (a, b) => a - b,     
      cell: (row) => row.rec_dealer_proceeds,  
    },   
    {
      name: 'Status',
      selector: (row) => row.rec_app_status,
      sortable: true,       
    },
    {
      name: 'Decision Date',
      selector: (row) => row.app_decision_date,
      sortable: true,          
    }

  ];

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  );
  
  const handleRowClick = (row) => {
    // window.location.href = `/rehash/${row.application_number}`;
    //alert(row.dealerId);    
    getApp(row.application_number, row.dealerId, row.rec_dealer_name)
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable className="align-middle mousepointer"    
    data={filteredItems}
    columns={columns}
    defaultSortField={['username', 'username']}
    striped
    pagination
    paginationPerPage={20}
    subHeader
    subHeaderComponent={subHeaderComponent}       
    onRowClicked={handleRowClick}
    headerCellStyle={{ textAlign: 'center' }}    
/>
  );
};

export default Table;
