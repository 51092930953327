const NoPage = () => {
    return (
        <>
            <div id="layoutAuthentication" style={{ backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)" }}>
                <h1 style={{ color: 'white', marginLeft: '10px', marginTop: '10px' }}>Page not found</h1><br />
            </div>
        </>
    );
};
  
export default NoPage;