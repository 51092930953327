import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from '../config.json';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [firstPassword, setFirstPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
    const [submittedPassword, setSubmittedPassword] = useState(true);
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmittedPassword(true);
        savePassword();
    };
    
    const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'firstPassword') {
            setFirstPassword(value);
        } else if (name === 'secondPassword') {
            setSecondPassword(value);
        }

        if (value.length >= 8) {
            if (containsUppercase(value)) {
                if (firstPassword == value || secondPassword == value) {
                    setSubmissionMessage('');
                    setSubmittedPassword(false);
                } else {
                    setSubmissionMessage('The password do not match.');
                    setSubmittedPassword(true);    
                    console.log(firstPassword + ' != ' + secondPassword);
                }
            } else {
                setSubmissionMessage('Password should contain at least one uppercase letter.');
                setSubmittedPassword(true);
            }
        } else {
            setSubmissionMessage('Password should be at least 8 characters long.');
            setSubmittedPassword(true);
        }
    }

    function containsUppercase(testString: string) {
        return /[A-Z]/.test(testString);
    }

    function savePassword() {
        if (firstPassword != secondPassword) {
            setSubmissionMessage('The passwords do not match.')
        } else {
            setSubmissionMessage('Updating your password...')

            localStorage.setItem('env', window.location.href.includes('usautocreditportal') ? '/prod' : '')
            fetch('https://nzbtlf8gre.execute-api.us-east-2.amazonaws.com' + localStorage.getItem('env') + '/dealerresetpassword', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': config.dealer_reset_password,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'password': firstPassword,
                    'token': token
                })
            })
                .then((response) => response.json())
                .then((response) => {
                    if (String(response.result).includes('successful')) {
                        setPasswordResetSuccessful(true);
                    } else {
                        setPasswordResetSuccessful(false);
                    }

                    setSubmissionMessage(response.result);
                    setSubmittedPassword(true);
                })
                .catch(function (err) {
                    console.log('Error:', err);
                });
        }
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <div id="layoutAuthentication" style={{ backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)" }}>
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container-xl px-4">
                            <div style={{ paddingTop: "175px" }} className="row justify-content-center">
                                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                    <div className="card my-5">
                                        <div className="card-body p-3 text-center">
                                            <div className="h3 fw-light mb-3">
                                                <img src="/images/us-auto-credit-white-logo.png" alt="Desc" height="100" />
                                            </div>
                                        </div>
                                        <h3 className="text-center">Change Password</h3>
                                        <div className="card-body p-4 pt-1">
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="text-gray-600 small" htmlFor="firstPassword">New Password:</label>
                                                    <input id="firstPassword" name="firstPassword" className="form-control form-control-solid" type="password" value={firstPassword} onChange={handleTextboxChange} autoFocus />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="text-gray-600 small" htmlFor="secondPassword">Confirm New Password:</label>
                                                    <input id="secondPassword" name="secondPassword" className="form-control form-control-solid" type="password" value={secondPassword} onChange={handleTextboxChange} />
                                                </div>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col text-center">
                                                            <br />
                                                            <button type="submit" className="btn btn-primary btn-block mb-4" disabled={submittedPassword}>Submit</button>
                                                            <div className="text-primary">
                                                                {submissionMessage}
                                                                {passwordResetSuccessful ? <><br /><br /><a href='/login' style={{ color: 'blue' }}><b>Click here</b></a> to log in.</> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div id="layoutAuthentication_footer">
                    <footer className="footer-admin mt-auto footer-dark">
                        <div className="container-xl px-4">
                            <div className="row">
                                <div className="col-md-6 small">Copyright &copy; US Auto Credit 2023</div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;