import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import config from '../config.json';

function Login() {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [submittedLogin, setSubmittedLogin] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [resetPasswordResult, setResetPasswordResult] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [submittedResetPasswordEmail, setSubmittedResetPasswordEmail] = useState(true);
    
    useEffect(() => {
        localStorage.setItem('env', window.location.href.includes('usautocreditportal') ? '/prod' : '');
    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmittedLogin(true);
        authenticate();
    };

    const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'reset_email') {
            setResetEmail(value);
            setResetPasswordResult('');

            if (value.includes('@') && value.includes('.')) {
                setSubmittedResetPasswordEmail(false);
            } else {
                setSubmittedResetPasswordEmail(true);
            }
        }
    }

    function authenticate() {
        fetch('https://ayw01ipn0d.execute-api.us-east-2.amazonaws.com' + localStorage.getItem('env') + '/dealerauthenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': config.dealer_authenticate,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'user_id': userId,
                'password': password
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response['token'].length === 32) {
                    localStorage.setItem('dealer_id', response.dealer_id);
                    localStorage.setItem('user_id', response.user_id);
                    localStorage.setItem('heimdall', response.token);
                    localStorage.setItem('first_name', response.first_name);
                    localStorage.setItem('last_name', response.last_name);
                    navigate('/');
                    window.location.reload();
                } else {
                    localStorage.setItem('dealer_id', '');
                    localStorage.setItem('user_id', '');
                    localStorage.setItem('heimdall', '');
                    localStorage.setItem('first_name', '');
                    localStorage.setItem('last_name', '');
                    setLoginErrorMessage('Invalid login');
                    setSubmittedLogin(false);
                }
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    function sendResetPasswordEmail() {
        setSubmittedResetPasswordEmail(true);
        setResetPasswordResult('Searching...');
        
        fetch('https://qd32imqy84.execute-api.us-east-2.amazonaws.com' + localStorage.getItem('env') + '/dealerforgotpassword', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': config.dealer_forgot_password_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email': resetEmail
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (String(response.result).includes('Email sent')) {
                    setSubmittedResetPasswordEmail(true);
                } else {
                    setSubmittedResetPasswordEmail(false);
                }

                setResetPasswordResult(response.result);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    return (
        <>
            <div id="layoutAuthentication" style={{ backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)" }}>
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container-xl px-4">
                            <div style={{ paddingTop: "175px" }} className="row justify-content-center">
                                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                    <div className="card my-5">
                                        <div className="card-body p-3 text-center">
                                            <div className="h3 fw-light mb-3">
                                                <img src="/images/us-auto-credit-white-logo.png" alt="Desc" height="100" />
                                            </div>
                                        </div>
                                        <h3 className="text-center">Login</h3>
                                        <div className="card-body p-4 pt-1">
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="text-gray-600 small" htmlFor="emailExample">Email:</label>
                                                    <input id="userId" name="userId" className="form-control form-control-solid" type="text" value={userId} onChange={(event) => setUserId(event.target.value)} autoFocus />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="text-gray-600 small" htmlFor="passwordExample">Password:</label>
                                                    <input className="form-control form-control-solid" type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                                                </div>
                                                
                                                <div className="mb-3">
                                                    <a className="small text-primary" href="auth-password-social.html" data-bs-toggle="modal" data-bs-target="#email_modal">Forgot password</a>
                                                </div>

                                                {/* <div className="d-flex align-items-center justify-content-between mb-0">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="checkRememberPassword" type="checkbox" value="" />
                                                        <label className="form-check-label" htmlFor="checkRememberPassword">Remember password</label>
                                                    </div>
                                                </div> */}

                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col text-center">
                                                            {/* <a className="btn btn-primary p-3" href="dashboard-1.html">Login</a> */}
                                                            <button type="submit" className="btn btn-primary btn-block mb-4" disabled={submittedLogin}>Login</button>
                                                            <div className="text-danger">{loginErrorMessage}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                {/* <div id="layoutAuthentication_footer">
                    <footer className="footer-admin mt-auto footer-dark">
                        <div className="container-xl px-4">
                            <div className="row">
                                <div className="col-md-6 small">Copyright &copy; US Auto Credit 2023</div>
                            </div>
                        </div>
                    </footer>
                </div> */}
            </div>

            {/* Email Modal */}
            <div className="modal fade" id="email_modal" tabIndex={-1} role="dialog" aria-labelledby="email_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Forgot Password</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="reset_email" className="col-form-label">Email address of your Dealer Portal account:</label>
                                    <input id="reset_email" name="reset_email" type="text" className="form-control" onChange={handleTextboxChange} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            {resetPasswordResult ? <span style={{ marginRight: '10px' }}>{resetPasswordResult}</span> : ''}
                            <button type="button" className="btn btn-primary" onClick={() => sendResetPasswordEmail()} disabled={submittedResetPasswordEmail}>Send Password Reset Link</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    
}

export default Login;
